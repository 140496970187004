import React from 'react'
import { PrizeAllocation, Prize } from 'api/results'
import { Button } from 'buttons'
import uuid from 'uuid/v4'
import { AuthContext } from 'auth/auth'
import { Grid, Typography, makeStyles, FormControl, InputLabel, MenuItem, Select, Slider, TextField, Box, Divider, CircularProgress } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment'
import { Field, FieldInputProps, FormikProps } from 'formik'
import _ from 'lodash'

const createAllocation = (owner_id: string, parent_id: string): PrizeAllocation => {
  return {
    "allocation_id": uuid(),
    "handout_method": {
      "description": "",
      "type": "redeem"
    },
    "owner_id": owner_id,
    "parent_id": parent_id,
    "prize": {
      "description": "",
      "title": "",
      "image_url": "",
      "prize_id": "",
      "timestamp": 0
    },
    "prize_id": "",
    "range": {
      "Max": 0,
      "Min": 0
    },
    "sort": 500,
    "type": "prize",
    "valid_until": moment().endOf("day").unix()
  }
}

const useStyles = makeStyles(theme => ({
  form: {
    flexGrow: 1,
  },
  box: {
    padding: theme.spacing(2),
    backgroundColor: "#f3f4f9",
    border: "2px solid #cfdae9",
    borderRadius: 4,
    position: 'relative',
    '&:not(:first-child)': {
      marginTop: theme.spacing(4)
    }
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
    marginBottom: 80,
  },
  divider: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: 'transparent',
  },
}));

type Props = {
  field: FieldInputProps<PrizeAllocation[]>
  form: FormikProps<any>
  disabled: boolean
  prizes: Prize[]
  gameId: string
}

export function GamePrizes(props: Props) {
  const { user } = React.useContext(AuthContext)
  const classes = useStyles()
  const prizeLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    if (prizeLabel && prizeLabel.current) {
      // @ts-ignore
      setLabelWidth(prizeLabel.current.offsetWidth)
    }
  }, []);

  return <>
    <Typography variant="h5" component="h2">
      <Box fontWeight="fontWeightMedium">PRIZES</Box>
    </Typography>
      {props.field.value.map((allo, index) => {
        const redeemTouched = _.get(props.form.touched, `${props.field.name}.${index}.handout_method.description`, false)
        const redeemError = _.get(props.form.errors, `${props.field.name}.${index}.handout_method.description`, "")
        console.log('p',props.field.value[0].range)
        return (
          <Box key={allo.allocation_id} className={classes.box}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" fullWidth style={{ background: '#FFF' }}>
                  <InputLabel ref={prizeLabel}>Select prize</InputLabel>
                  <Field
                    name={`${props.field.name}.${index}.prize_id`}
                    as={Select}
                    onChange={e => {
                      props.form.setFieldValue(`${props.field.name}.${index}.prize`, props.prizes.find(p => p.prize_id === e.target.value))
                      props.form.setFieldValue(`${props.field.name}.${index}.prize_id`, e.target.value)
                    }}
                    labelId="prize-select"
                    labelWidth={labelWidth}
                    disabled={props.disabled}
                    displayEmpty
                  >
                    {props.prizes.map(opt => <MenuItem key={opt.prize_id} value={opt.prize_id}>{opt.title}</MenuItem>)}
                  </Field>
                </FormControl>
                <br />
                <br />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Field
                    name="valid_until"
                    onChange={date => props.form.setFieldValue(`${props.field.name}.${index}.valid_until`, moment(date).endOf("day").unix())}
                    type="text"
                    value={Math.floor(allo.valid_until * 1000)}
                    label="Expiry date"
                    variant="inline"
                    format="dd/MM/yyyy"
                    disabled={props.disabled}
                    minDate={new Date()}
                    as={KeyboardDatePicker}
                    KeyboardButtonProps={{ 'aria-label': 'set expiry date' }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography gutterBottom>
                  Winner range
                </Typography>
                <Slider
                  min={1}
                  max={100}
                  value={[allo.range.Min + 1, allo.range.Max + 1]}
                  onChange={(event, newValue) => {
                    const Min = newValue[0] > 0 ? newValue[0] - 1 : 0
                    const Max = newValue[1] > 0 ? newValue[1] - 1: 0
                    props.form.setFieldValue(`${props.field.name}.${index}.range`, { Min, Max })
                  }}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                />
                <Grid container item xs={true} spacing={4}>
                  <Grid item xs={6} sm={4}>
                    <Field
                      name={`allocations.${index}.range.Min`}
                      value={allo.range.Min + 1}
                      onChange={e => {
                        if (e.target.value < 1) return
                        props.form.setFieldValue(`${props.field.name}.${index}.range.Min`, e.target.value - 1)
                      }}
                      disabled={props.disabled}
                      as={TextField}
                      type="number"
                      label="Min"
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Field
                      name={`allocations.${index}.range.Max`}
                      disabled={props.disabled}
                      onChange={e => {
                        if (e.target.value < 1) return
                        props.form.setFieldValue(`${props.field.name}.${index}.range.Max`, e.target.value - 1)
                      }}
                      as={TextField}
                      value={allo.range.Max + 1}
                      type="number"
                      label="Max"
                    />
                  </Grid>
                  <Grid container item xs={12} sm={4} alignItems="flex-end">
                    <Typography>Total prizes: {allo.range.Max - allo.range.Min + 1}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  fullWidth
                  style={{ background: '#FFF' }}
                  name={`${props.field.name}.${index}.handout_method.description`}
                  label="Redeem explanation"
                  type="text"
                  variant="outlined"
                  multiline
                  disabled={props.disabled}
                  as={TextField}
                />
                {redeemTouched ? <Grid item xs="auto"><Box component="p" style={{ color: "red" }}>{redeemError}</Box></Grid> : null}
              </Grid>
            </Grid>
          </Box>
        )
      })}
      <Button
        onClick={() => props.form.setFieldValue(props.field.name, [
          ...props.field.value.concat(createAllocation(user.owner_id, props.gameId))
        ])}
        type="button"
        color="default"
        variant="contained"
        style={{ marginTop: 12 }}
      >
        Add Prize
    </Button>
  </>
}
