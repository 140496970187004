import http from "http/index"

export type Sponsor = {
  sponsor_id: string
  name: string
  images: {
    logo_url: string
    banner_url: string
  }
}

export function getSponsor(sponsorId: string): Promise<Sponsor> {
  return http.get(`/sponsors/${sponsorId}`).then(res => res.data)
}

export function upsertSponsor(sponsorId: string, data: Sponsor): Promise<Sponsor> {
  return http.put(`/sponsors/${sponsorId}`, data).then(res => res.data)
}

export function listSponsors(): Promise<Sponsor[]> {
  return http.get('/sponsors').then(res => res.data)
}

export default { getSponsor, upsertSponsor, listSponsors }