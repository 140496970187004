import axios from 'axios';
import uuid from "uuid/v4";
import jwtDecode from 'jwt-decode';


export function checkTokenValidation() {
  const token = localStorage.getItem('3rdPartyToken');
  if (token) {
    const tokenData = jwtDecode(token);
    if (tokenData.exp * 1000 < Date.now()) {
      localStorage.clear();
      window.location.href = '/session-expired';
    }
    else return token;
  }
  localStorage.clear();
  window.location.href = '/session-expired';
  return '';
}

export function setAuthToken(token: string) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

// const basePath = "https://api.xsided.io"
const basePath = "https://dev-livequiz-api.manydigital.com/v1"


function get(url: string) {
  return axios.get(basePath + url, {
    headers: {
      'X-Trace-Id': uuid()
    }
  }).then(res => res.data)
}

function put(url: string, data?: any) {
  return axios.put(basePath + url, data, {
    headers: {
      'X-Trace-Id': uuid()
    }
  }).then(res => res.data)
}

export default { get, put }