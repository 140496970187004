import React, { useState } from "react"
import { useEventList } from "react-http";
import { Event } from "api/events"
import { Link, Redirect } from "react-router-dom"
import { Button } from "buttons";
import uuid from "uuid";
import moment from "moment";
import { Box, CircularProgress, Grid, Paper } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import Toolbar from '@material-ui/core/Toolbar';

type EventListProps = {
  events: Event[]
  loading: boolean
  createEvent: () => void
}

type RowProps = {
  startDate: number
  endDate: number
  title: string
  category: string
  eventId: string
}

const Row: React.FC<RowProps> = ({ startDate, endDate, title, category, eventId }) => (
  <TableRow selected={moment.unix(endDate).isBefore(moment())} hover>
    <TableCell>{moment.unix(startDate).format("DD-MM-YYYY")}</TableCell>
    <TableCell>{title}</TableCell>
    <TableCell>{category}</TableCell>
    <TableCell><Link to={`/service/events/${eventId}`}>edit</Link></TableCell>
  </TableRow>
)

export function List({ events, loading, createEvent }: EventListProps) {
  const heldEvents = events.filter(e => moment.unix(e.end_date).isBefore(moment()))
  const upcoming = events.filter(e => moment.unix(e.end_date).isAfter(moment()))
  const [showExpired, setShowExpired] = React.useState(false)

  if (loading) {
    return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </div>
  }

  return <>
    <Grid container justify="space-between" direction="row">
      <Typography variant="h4" component="h1">
        <Box fontWeight="fontWeightMedium">EVENT</Box>
      </Typography>
      <Typography variant="h6" component="h4" style={{ cursor: "pointer" }} onClick={() => setShowExpired(!showExpired)}>{showExpired ? "- Hide" : "+ Show"} expired events</Typography>
    </Grid>
    <hr />
    <Collapse in={showExpired}>
      <Paper style={{ marginBottom: 24 }}>
        <Box width={1} style={{ overflowX: 'auto' }}>
          <Toolbar>
            <Typography variant="h6" component="h4">
              Expired events
            </Typography>
          </Toolbar>
          <Table stickyHeader>
            <TableHead>
              <TableRow style={{ background: 'green', color: 'blue' }}>
                <TableCell>Start date</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Category</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {heldEvents.map(event => <Row
                key={event.event_id}
                startDate={event.start_date}
                endDate={event.end_date}
                title={event.title}
                category={event.category}
                eventId={event.event_id}
              />)}
            </TableBody>
          </Table>
        </Box>
      </Paper>
    </Collapse>

    <Paper>
      <Box width={1} style={{ overflowX: 'auto' }}>
        <Toolbar>
          <Typography variant="h6" component="h4">
            Upcoming events
          </Typography>
        </Toolbar>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Start date</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Category</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {upcoming.map(event => <Row
              key={event.event_id}
              startDate={event.start_date}
              endDate={event.end_date}
              title={event.title}
              category={event.category}
              eventId={event.event_id}
            />)}
          </TableBody>
        </Table>
      </Box>
    </Paper>

    <div style={{ marginTop: 24, marginBottom: 24 }}>
      <Button onClick={createEvent} color="default" variant="contained">Create event</Button>
    </div>
  </>
}

export default function EventList() {
  const [events, error, loading] = useEventList()
  const [redirect, setRedirect] = useState(false)

  const createEvent = () => setRedirect(true)

  if (redirect) {
    return <Redirect to={`/service/events/${uuid()}?new=true`} />
  }

  if (error) {
    return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography component="h1" variant="body1">
        <Box whiteSpace="pre" textAlign="center">{`An error occured... \nWe couldn't retrieve your events.`}</Box>
      </Typography>
    </div>
  }

  return <List events={events} loading={loading} createEvent={createEvent} />
}