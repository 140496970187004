import React, { useState, useEffect } from 'react'
import { FanshoutCollection } from '../api/fanshouts';
import api from 'api/index';
import uuid from 'uuid/v4';
import logger from 'xsided/xsided-logger-js';

type SaveFanshoutList = (list: FanshoutCollection) => Promise<FanshoutCollection>
export function useFanshoutCollection(list_id: string): [FanshoutCollection, SaveFanshoutList, API.Error, boolean] {
  type State = { collection: FanshoutCollection, loading: boolean, error?: API.Error }
  const [state, setState] = useState<State>({ collection: { list_id, name: "", fanshouts: [{ fanshout_id: uuid(), value: "" }] }, loading: false })

  useEffect(() => {
    async function fetch() {
      try {
        setState({ ...state, loading: true })
        const res = await api.getFanshoutCollection(list_id)
        setState({ ...state, collection: res, loading: false, error: null })
      } catch(e) {
        setState({ ...state, loading: false, error: e })
        if (e.response && e.response.status === 404) {
          // TODO fix
          return
        }
        logger.error(e, "Failed to fetch fanshout collection")
      }
    }
    fetch()
  }, [list_id])

  const save = (collection: FanshoutCollection): Promise<FanshoutCollection> => api.upsertFanshoutCollection(collection.list_id, collection)

  return [state.collection, save, state.error, state.loading]
}

export function useFanshoutCollectionList(): ReadHook<FanshoutCollection[], API.Error> {
  type State = { collections: FanshoutCollection[], loading: boolean, error?: API.Error }
  const [state, setState] = useState<State>({ collections: [], loading: false })

  useEffect(() => {
    async function fetch() {
      try{
        setState({ ...state, loading: true })
        const res = await api.listFanshoutCollections()
        setState({ ...state, collections: res, loading: false })
      } catch(e) {
        logger.error(e, "Failed to list fanshouts collections")
        alert('Failed to list fanshouts collections')
      }
    }
    fetch()
  }, [])

  return [state.collections, state.error, state.loading]
}