import React from "react"
import api from 'api';
import { Event } from "api/events";
import { RouteComponentProps, Prompt } from "react-router-dom";
import { Button } from "buttons";
import { ImageDropZoneWithLabel } from "forms/image";
import cdn from 'http/cdn'
import { GameCard } from "games/card";
import { AuthContext } from "auth/auth";
import { Category } from "api/categories";
import { useCategories } from "react-http/categories";
import { Grid, Typography, Box, MenuItem, InputLabel, FormControl, CircularProgress, TextField, Select } from "@material-ui/core";
import { Breadcrumbs, CrumbLink, Crumb } from '../breadcrumbs'
import { TextFieldWithCounter } from 'forms/text-field-with-counter';
import { makeStyles } from "@material-ui/styles";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import moment from "moment";
import { XsidedSnackbar, XsidedSnackProps } from "snackbar";
import logger from "xsided/xsided-logger-js";
import { message } from "forms/prompt-text";
import uuid from "uuid";
import queryString from 'query-string';

type EventListProps = {
  event: Event
  changeImage: (file: File) => Promise<string>
  createGame: () => Promise<string>
  categories: Category[]
  canEnterCode: boolean
  removeQueryParams: () => void
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  text_field: {
    width: '100%',
  },
  buttonContainer: {
    marginBottom: 80,
  }
}));

const eventValidationSchema = Yup.object().shape({
  title: Yup.string().min(2, "Event title must be at least 2 characters").max(50, "Event title must be at max 50 characters").required("Event title is required"),
  code: Yup.string().min(4, "Event code must consist of at least 4 numbers").matches(/^[0-9]*$/, "Event code must only consist of numbers")
})

export function Details({ event, changeImage, createGame, categories, canEnterCode, removeQueryParams }: EventListProps) {
  const categoryOptions = categories.map(cat => ({ key: cat.category_id, value: cat.category_id, text: cat.name }))
  const classes = useStyles()
  const [snack, setSnack] = React.useState<XsidedSnackProps>({ show: false, variant: "success", message: "Game saved", autoHideDuration: 3000 });
  const { user } = React.useContext(AuthContext);

  const canSetType = ["admin"].includes(user.role)
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  const addGame = async(event: Event): Promise<Event> => {
    try {
      const gameId = await createGame()
      setSnack({ show: true, variant: "info", message: "Create game...", autoHideDuration: null })
      const res = await api.saveEvent({ ...event, games: event.games.concat(gameId) })
      setSnack({ show: true, variant: "success", message: "Added game to event", autoHideDuration: 3000 })
      return res.data
    } catch(e) {
      setSnack({ show: true, variant: "error", message: "Failed to add game to event", autoHideDuration: null })
      logger.error(e, "Failed to create game")
      return event
    }
  }

  React.useEffect(() => {
    if (inputLabel && inputLabel.current) {
      // @ts-ignore
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);

  return <>
    <Breadcrumbs aria-label="breadcrumb">
      <CrumbLink href="/service/events">Event list</CrumbLink>
      <Crumb>Event</Crumb>
    </Breadcrumbs>
    <Typography variant="h4" component="h1">
      <Box fontWeight="fontWeightMedium">EVENT</Box>
    </Typography>
    <hr />
    <Formik
      initialValues={event}
      validationSchema={eventValidationSchema}
      onSubmit={async (data, { resetForm }) => {
        try {
          setSnack({ show: true, variant: "info", message: "Saving...", autoHideDuration: null })
          const res = await api.saveEvent(data)
          resetForm({ values: res.data })
          removeQueryParams()
          setSnack({ show: true, variant: "success", message: "Event saved", autoHideDuration: 3000 })
        } catch(err) {
          logger.error(err, "Failed to save agenda", { event })
          setSnack({ show: true, variant: "error", message: "Failed to save event", autoHideDuration: null })
        }
      }}
    >
      {({ values, handleSubmit, setFieldValue, setValues, isSubmitting, setSubmitting, resetForm, errors, touched, dirty, initialValues }) => (
        <>
          <form autoComplete="off" className={classes.root} onSubmit={handleSubmit}>
            <Prompt
              when={dirty}
              message={message}
            />
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Field
                  name="title"
                  label="Event title"
                  type="text"
                  variant="outlined"
                  disabled={isSubmitting}
                  maxLength={50}
                  className={classes.text_field} as={TextFieldWithCounter}
                  error={touched.title && !!errors.title}
                  helperText={touched.title ? errors.title : ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel ref={inputLabel} id="category-select">
                    Category
                  </InputLabel>
                  <Field
                    name="category_id"
                    as={Select}
                    labelId="category-select"
                    labelWidth={labelWidth}
                    disabled={isSubmitting}
                    displayEmpty
                    onChange={e => {
                      const category = categoryOptions.find(c => c.value === e.target.value)
                      if (!category) return
                      setFieldValue("category_id", category.value)
                      setFieldValue("category", category.text)
                    }}
                  >
                    {categoryOptions.map(opt => <MenuItem key={opt.key} value={opt.value}>{opt.text}</MenuItem>)}
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="description"
                  label="Description"
                  type="text"
                  variant="outlined"
                  disabled={isSubmitting}
                  className={classes.text_field}
                  as={TextField}
                />
              </Grid>
              {canSetType ? 
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      name="quiz_type"
                      label="Event type"
                      type="text"
                      variant="outlined"
                      disabled={isSubmitting}
                      maxLength={50}
                      className={classes.text_field} as={TextFieldWithCounter}
                    />
                </FormControl>
                </Grid>
              : null }
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <ImageDropZoneWithLabel
                  disabled={isSubmitting}
                  dimensions={{
                    width: 750,
                    height: 300
                  }}
                  removeImage={() => setFieldValue("hero_image_url", "")}
                  label="Event image"
                  src={values.hero_image_url}
                  changeImage={img => changeImage(img)
                    .then(path => setFieldValue("hero_image_url", path))
                    .catch(err => setSnack({ show: true, variant: "error", message: "Failed to update event image", autoHideDuration: null }))
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Field
                        name="start_date"
                        onChange={date => setFieldValue("start_date", moment(date).startOf("day").unix())}
                        type="text"
                        value={Math.floor(values.start_date * 1000)}
                        label="Start date"
                        variant="inline"
                        format="dd/MM/yyyy"
                        disabled={isSubmitting}
                        minDate={new Date()}
                        as={KeyboardDatePicker}
                        KeyboardButtonProps={{ 'aria-label': 'set start date' }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="end_date"
                        onChange={date => setFieldValue("end_date", moment(date).endOf("day").unix())}
                        type="text"
                        value={Math.floor(values.end_date * 1000)}
                        label="End date"
                        variant="inline"
                        format="dd/MM/yyyy"
                        disabled={isSubmitting}
                        minDate={moment.unix(values.start_date).valueOf()}
                        as={KeyboardDatePicker}
                        KeyboardButtonProps={{ 'aria-label': 'set end date' }}
                      />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Field
                  name="code"
                  error={touched.code && !!errors.code}
                  helperText={touched.code ? errors.code : ""}
                  label="Code"
                  type="text"
                  variant="outlined"
                  disabled={isSubmitting || !canEnterCode}
                  className={classes.text_field}
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={isSubmitting || !dirty}
                  style={{ float: 'right' }}
                  color="default"
                  variant="contained"
                >
                  Save event
                </Button>
              </Grid>
            </Grid>
          </form>
          {values.games.map((gameId, index) => <GameCard key={gameId} eventId={event.event_id} gameId={gameId} number={index + 1} />)}
          {
            !initialValues.title ?
            null
            :
            <>
            <hr />
            <Grid container className={classes.buttonContainer}>
              <Button onClick={async() => {
                setSubmitting(true)
                const event = await addGame(values)
                setSubmitting(false)
                resetForm({ values: event })
              }} color="default" variant="contained">Add Quiz</Button>
            </Grid>
            </>
          }
        </>
      )}
    </Formik>
    <XsidedSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      show={snack.show}
      autoHideDuration={snack.autoHideDuration}
      onClose={() => setSnack(snack => ({ ...snack, show: false }))}
      variant={snack.variant}
      message={snack.message}
    />
  </>
}

export default function EventDetails(props: RouteComponentProps<{ eventId: string }>) {
  const eventId = props.match.params.eventId
  const [state, setState] = React.useState<{ loading: boolean, event: Event, error?: boolean }>({ loading: true, event: { event_id: eventId, start_date: moment().startOf("day").unix(), end_date: moment().endOf("day").unix(), games: [], category_id: "", category: "", code: "", title: "", description: "" } })
  const { user } = React.useContext(AuthContext)
  const [categories, categoryError, categoriesLoading] = useCategories()
  const canEnterCode = ["admin", "owner", "organizer"].includes(user.role)
  const queryParams = queryString.parse(props.location.search)

  const changeImage = (file: File): Promise<string> => {
    return cdn.uploadImage(file, "event-image", user.owner_id).then(({ path }) => path)
  }

  const createGame = async(): Promise<string> => {
    const gameId = uuid()
    const agendaId = uuid()

    await api.upsertAgenda(agendaId, {
      "agenda_id": agendaId,
      "tickspeed": {
          "interval": 1000,
          "unit": "ms"
      },
      "frames": [
        {
          "data": {},
          "frame_id": uuid(),
          "frame_type": "pre_game",
          "progression_type": "time",
          "ticks": 180,
          "transition": {
            "ticks": 1,
            "type": "fade"
          },
          "type": "fanshout"
        },
        {
          "data": {},
          "frame_id": uuid(),
          "frame_type": "pre_game",
          "progression_type": "time",
          "ticks": 10,
          "transition": {
            "ticks": 1,
            "type": "fade"
          },
          "type": "countdown"
        },
        {
          "data": {},
          "frame_id": uuid(),
          "frame_type": "breaker",
          "progression_type": "time",
          "ticks": 20,
          "transition": {
            "ticks": 1,
            "type": "fade"
          },
          "type": "winner"
        },
        {
          "data": {},
          "frame_id": uuid(),
          "frame_type": "finished",
          "progression_type": "redirect",
          "ticks": 1,
          "type": "finished"
        },
        {
          "data": {},
          "frame_id": uuid(),
          "frame_type": "quit",
          "progression_type": "redirect",
          "ticks": 1,
          "type": "redirect"
        }
      ],
      "language": "da"
    })
    await api.upsertGame(gameId, { game_id: gameId, sponsor_id: "", agenda_id: agendaId, fanshout_collections: [] })
    return gameId
  }

  const removeQueryParams = () => {
    if (queryParams.new) {
      props.history.push(props.location.pathname)
    }
  }

  React.useEffect(() => {
    async function fetch() {
      try {
        setState(state => ({ ...state, loading: true }))
        const event = await api.getEvent(eventId)
        setState(({ loading: false, event }))
      } catch(e) {
        logger.error(e, "Failed to fetch event", eventId)
        setState(state => ({ ...state, loading: false, error: true }))
      }
    }

    if (!queryParams.new) {
      fetch()
    } else if (!canEnterCode) {
      api.getEventCode()
        .then(code => {
          setState(state => ({ ...state, loading: false, event: { ...state.event, code }}))
        })
        .catch(e => logger.error(e, "Failed to get event code", { owner_id: user.owner_id, role: user.role, user_id: user.user_id }))
    } else {
      setState(state => ({ ...state, loading: false }))
    }
  }, [eventId, canEnterCode])

  if (state.loading || categoriesLoading || state.loading) {
    return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </div>
  }

  if (state.error) {
    return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography component="h1" variant="body1">
        <Box whiteSpace="pre" textAlign="center">{`An error occured... \nWe couldn't retrieve your event.`}</Box>
      </Typography>
    </div>
  }

  return <Details
    removeQueryParams={removeQueryParams}
    event={state.event}
    changeImage={changeImage}
    createGame={createGame}
    categories={categories}
    canEnterCode={canEnterCode}
  />
}
