import * as React from 'react'

type Props = {

}

const SessionExpired = (props: Props) => {
  return (
    <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <h3>The session has been expired. </h3>
      <h5>Please try again to login.</h5>
      <p>If you think this is a mistake, please contact to the Administrator. </p>
    </div>
  )
}

export default SessionExpired