import http from "http/index"

async function getUser(user_id: string): Promise<UserData> {
  return http.get(`/users/${user_id}`).then(res => res.data)
}

export type UserData = {
  user_id: string
  name: string
  gender: string
  birthday: string
  picture: string
}

export default {
  getUser,
}