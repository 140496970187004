import React from 'react'
import './number-section.scss'

type Props = {
  children: React.ReactNode
}

export function NumberSection(props: Props) {
  return (
    <div className="launch-pad-number-section">
      <h1>{props.children}</h1>
    </div>
  )
}