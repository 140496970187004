import React, { useEffect, useState } from "react";
import { getUserManager } from "./user-manager";
import { User, Settings } from './types'
import api from "api";
import Logger from "xsided/xsided-logger-js";
import { CircularProgress } from "@material-ui/core";
import jwtDecode from 'jwt-decode';
import { checkTokenValidation, setAuthToken } from "../http";

interface Props {
  children: (user: IUser) => React.ReactNode
}

type Role = "admin" | "owner" | "organizer" | "publisher" | "player" | "user"

type IUser = {
  owner_id: string
  user_id: string
  role: Role
}

type AuthContext = {
  user: IUser
  settings: Settings
}

const defaultSettings = { settings_id: "", client_id: "", theme_id: "", owner_id: "", url: "" }
const defaultUser = {
  owner_id: "",
  user_id: "",
  role: "user" as Role
}

const AuthContext = React.createContext<AuthContext>({
  user: defaultUser,
  settings: defaultSettings
})

const AuthProvider = (props: Props) => {
  const [user, setUser] = React.useState<IUser>(defaultUser)
  const [localToken, setLocalToken] = useState<string|null>(null);
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = React.useState<Settings>(defaultSettings)
  const [error, setError] = React.useState(false)

  const queryParams = new URLSearchParams(window.location.search);
  const org = queryParams.get("org");
  let token = queryParams.get("token");

  useEffect(() => {
    if (org && token) {
      localStorage.setItem('3rdPartyToken', token);
      setLocalToken(token);
      api.getSettings(org)
        .then((settings) => { 
          setSettings(settings as Settings); 
          localStorage.setItem('settings', JSON.stringify(settings));
        })
        .catch(err => {
          setError(err)
          console.error('Failed to fetch settings', { err, org: org })
        })
    } else {
      const lToken = checkTokenValidation();
      const localSettings = localStorage.getItem('settings');
      if(localSettings){
        setSettings(JSON.parse(localSettings));
        setLocalToken(lToken);
      }      
    }
  }, [])

  useEffect(() => {
    if (settings.owner_id && localToken) {
      setAuthToken(`${localToken}.BO.${settings.owner_id}`);
      api.getUserDetailsFromAuth()
        .then((user: User) => {
          const decodedToken = jwtDecode(user.access_token)
          setUser({
            owner_id: user.profile.owner_id,
            user_id: user.profile.sub,
            role: decodedToken.role
          })
          setAuthToken(`${user.access_token}.BO`);
          setError(false);
          setLoading(false);
          Logger.user_id = user.profile.sub
        })
        .catch((err) => {
          console.log(err);
          
          setError(err);
        })
    }
  }, [settings.client_id])

  if (error) {
    return <div>error</div>
  }

  if (loading) {
    return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </div>
  }

  return (
    <AuthContext.Provider value={{ user, settings }}>
      {props.children(user)}
    </AuthContext.Provider>
  )
}

export { AuthContext }
export default AuthProvider