import events from './events'
import games from './games'
import results from './results'
import users from './users'
import fanshouts from './fanshouts'
import sponsors from './sponsors'
import settings from './settings'
import categories from './categories'
import auth from './auth'

export {}
export default {
  ...events,
  ...games,
  ...results,
  ...users,
  ...fanshouts,
  ...sponsors,
  ...settings,
  ...categories,
  ...auth
}