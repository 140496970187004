import React from 'react'
import { Button } from 'buttons'
import uuid from 'uuid'
import { RouteComponentProps } from 'react-router'
import { useSponsorList } from 'react-http/sponsors'
import { Sponsor } from 'api/sponsors'
import './list.scss'
import { Typography, Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@material-ui/core';
import { Link, Redirect } from "react-router-dom"

type Props = {
  sponsors: Sponsor[]
  createSponsor: () => void
}

const List2 = (props: Props) => {
  return (
    <>
      <Typography variant="h4" component="h1">
        <Box fontWeight="fontWeightMedium">Sponsors</Box>
      </Typography>
      <hr />
      <Paper>
        <Box width={1} style={{ overflowX: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Sponsor name</TableCell>
                <TableCell>Logo</TableCell>
                <TableCell>Banner</TableCell>
                <TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.sponsors.map(sponsor => (
                <TableRow hover key={sponsor.sponsor_id}>
                  <TableCell>{sponsor.name}</TableCell>
                  <TableCell><img src={sponsor.images.logo_url} height="60" /></TableCell>
                  <TableCell><img src={sponsor.images.banner_url} height="60" /></TableCell>
                  <TableCell><Link to={`/service/sponsors/${sponsor.sponsor_id}`}>edit</Link></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Paper>

      <div style={{ marginTop: 24, marginBottom: 24 }}>
        <Button onClick={props.createSponsor} color="default" variant="contained">Create sponsor</Button>
      </div>
    </>
  )
}

export function SponsorList(props: RouteComponentProps) {
  const [list, error, loading] = useSponsorList()
  const [redirect, setRedirect] = React.useState(false)

  if (redirect) {
    return <Redirect to={`/service/sponsors/${uuid()}`} />
  }

  if (loading) {
    return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </div>
  }

  if (error) {
    return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography component="h1" variant="body1">
        <Box whiteSpace="pre" textAlign="center">{`An error occured... \nWe couldn't retrieve your sponsors.`}</Box>
      </Typography>
    </div>
  }

  return <List2
    sponsors={list}
    createSponsor={() => setRedirect(true)}
  />
}