import React from 'react'
import './pad.scss'
import { IoIosFlash } from 'react-icons/io'
import { RouteComponentProps } from 'react-router-dom'
import { Trapezoid } from 'trapezoid/trapezoid'
import { LaunchPadDone } from './done'
import { LaunchPadReady } from './ready'
import { Event } from 'api/events'
import { useEvent } from 'react-http'
import { FaSpinner } from 'react-icons/fa'
import moment from 'moment'
import api from 'api'
import { GameInstance, Game } from 'api/games'

type Props = {
  activeTab: "ready" | "done"
  changeTab: (tab: string) => void
  event: Event
  instances: GameInstance[][]
  games: Game[]
}

function Pad(props: Props) {
  const launchableGames = props.games
    .map((game, index) => ({ ...game, index }))
    .filter(game => !props.instances.some(instances => instances.some(inst => inst.game_id === game.game_id)))
  const launchedGames = props.games
    .map((game, index) => ({ ...game, index }))
    .filter(game => props.instances.some(instances => instances.some(inst => inst.game_id === game.game_id)))

  return (
    <div className="launch-pad-container">
      <h1>{props.event.title} <span>{moment.unix(Number(props.event.start_date)).format("DD-MM-YYYY")}</span></h1>
      <Trapezoid backgroundColor={props.activeTab === "ready" ? "#f2f2f2" : "#dbdbdb"} onClick={() => props.changeTab("ready")}>
        <div className={`launch-pad-ready-tab ${props.activeTab === "ready" ? "launch-pad-tab-active" : ""}`}>
          <span><IoIosFlash /></span>
          <h1 style={{ fontSize: 16 }}>LAUNCH PAD</h1>
        </div>
      </Trapezoid>
      <Trapezoid backgroundColor={props.activeTab === "done" ? "#f2f2f2" : "#dbdbdb"} onClick={() => props.changeTab("done")}>
        <div className={`launch-pad-done-tab ${props.activeTab === "done" ? "launch-pad-tab-active" : ""}`}>
          <h1 style={{ fontSize: 16 }}>Done</h1>
          <p><span>{launchedGames.length}</span></p>
        </div>
      </Trapezoid>
      <div className="launch-pad">
        {props.activeTab === "ready" ?
          <LaunchPadReady event={props.event} games={launchableGames} />
          :
          <LaunchPadDone instances={props.instances} games={launchedGames} />
        }
      </div>
    </div>
  )
}

type State = { instances: GameInstance[][], games: Game[], loading: boolean }
export default function LaunchPad(props: RouteComponentProps<{event_id: string}>) {
  const { event, error, loading } = useEvent(props.match.params.event_id)
  const [state, setState] = React.useState<State>({ games: [], instances: [], loading: true })

  const activeTab = props.match.path.endsWith("ready") ? "ready" : "done"

  function fetchInstances() {
    return Promise.all(event.games.map(gameId => api.getGameInstances(gameId)))
    .then((res) => setState(state => ({ ...state, instances: res })))
    .catch(e => console.error('Error fetching game instances', e))
  }

  function fetchGames() {
    return Promise.all(event.games.map(gameId => api.getGame(gameId)))
    .then((res) => setState(state => ({ ...state, games: res })))
  }

  React.useEffect(() => {
    if (event.games.length) {
      window.addEventListener("focus", () => fetchInstances())
      return window.removeEventListener("focus", () => fetchInstances())
    }
  }, [event.games])

  React.useEffect(() => {
    if (!loading) {
      (async function fetch() {
        setState(state => ({ ...state, loading: true }))
        await fetchInstances()
        await fetchGames()
        setState(state => ({ ...state, loading: false }))
      })()
    }
  }, [event])

  const changeTab = (tab: string) => {
    Promise.all([
      fetchGames(),
      fetchInstances(),
    ]).catch(e => console.error('Error changing tab', e))
    props.history.push(`/launch/pad/${props.match.params.event_id}/${tab}`)
  }

  if (loading || state.loading) {
    return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <FaSpinner style={{ color: '#FFF', fontSize: 80 }} className="icon-spin" />
    </div>
  }

  return <Pad
    activeTab={activeTab}
    changeTab={changeTab}
    event={event}
    instances={state.instances}
    games={state.games}
  />
}
