import * as React from 'react'
import { makeStyles, Typography, Box, Grid, FormControl, InputLabel, Select, MenuItem, Divider } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { Button } from 'buttons'
import { Formik, Field } from 'formik'
import { Game, Agenda, Frame } from 'api/games'
import { message } from 'forms/prompt-text'
import { Sponsor } from 'api/sponsors'
import { FanshoutCollection } from 'api/fanshouts'
import EditAgenda from './edit-agenda'
import { PrizeAllocation, Prize } from 'api/results'
import { GamePrizes } from './prizes'
import api from 'api'
import { XsidedSnackProps } from 'snackbar'
import logger from 'xsided/xsided-logger-js'
import * as Yup from 'yup'

type Props = {
  closeModal: () => void
  number: number
  game: Game
  agenda: Agenda
  sponsors: Sponsor[]
  prizes: Prize[]
  fanshoutCollections: FanshoutCollection[]
  prizeAllocations: PrizeAllocation[]
  setSnack: (Snack: XsidedSnackProps) => void
  setData: (game: Game, agenda: Agenda, prizeAllocations: PrizeAllocation[]) => void
}

const useStyles = makeStyles(theme => ({
  form: {
    flexGrow: 1,
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: theme.spacing(5, 5, 3),
    padding: theme.spacing(1, 1, 1),
    cursor: 'pointer',
  },
  container: {
    padding: theme.spacing(2,2,2),
  }
}))

const validationSchema = Yup.object().shape({
  agenda: Yup.object().shape({
    frames: Yup.array().of(
      Yup.lazy(frame => {
        if ((frame as Frame<any>).type === "quiz") {
          return Yup.object().shape({
            data: Yup.object().shape({
              answers: Yup.array().of(
                Yup.object().shape({
                  answer: Yup.array().of(
                    Yup.object().shape({
                      letter: Yup.string().required(),
                      locale: Yup.string().required(),
                      value: Yup.string().required("Answer field is required"),
                    })
                  ),
                  correct: Yup.bool(),
                  answer_id: Yup.string()
                })
              ),
              question: Yup.array().of(
                Yup.object().shape({
                  locale: Yup.string().required(),
                  value: Yup.string().required("Question field is required")
                })
              )
            })
          })
        }

        return Yup.mixed()
      })
    )
  }),
  prizeAllocations: Yup.array().of(
    Yup.object().shape({
      handout_method: Yup.object().shape({
        description: Yup.string().required("Redeem explanation is required")
      })
    })
  )
})

const GameCardModal = (props: Props) => {
  const classes = useStyles()
  const sponsorLabel = React.useRef(null);
  const fanshoutsLabel = React.useRef(null);
  const languageLabel = React.useRef(null);
  const [sponsorLabelWidth, setSponsorLabelWidth] = React.useState(0);
  const [fanshoutLabelWidth, setFanshoutLabelWidth] = React.useState(0);
  const [languageLabelWidth, setLanguageLabelWidth] = React.useState(0);

  React.useEffect(() => {
    if (sponsorLabel && sponsorLabel.current) {
      // @ts-ignore
      setSponsorLabelWidth(sponsorLabel.current.offsetWidth);
    }

    if (fanshoutsLabel && fanshoutsLabel.current) {
      // @ts-ignore
      setFanshoutLabelWidth(fanshoutsLabel.current.offsetWidth)
    }

    if (languageLabel && languageLabel.current) {
      // @ts-ignore
      setLanguageLabelWidth(languageLabel.current.offsetWidth)
    }
  }, [sponsorLabel, fanshoutsLabel, languageLabel]);

  return (
    <>
      <Typography component="h1" variant="h4" style={{ marginBottom: 12 }}>
        <Box component="span" fontWeight="fontWeightBold">Game {props.number}</Box>
      </Typography>
      <Formik
        initialValues={{ game: props.game, agenda: props.agenda, prizeAllocations: props.prizeAllocations }}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={async (data, { resetForm, setFieldError, ...rest }) => {
          const errors = data.agenda.frames.filter((f, i) => {
            if (f.type !== "quiz") return false
            const anyCorrect = f.data.answers.some(a => a.correct)
            if (!anyCorrect) {
              setFieldError(`agenda.frames.${i}.data.answers.missingCorrect`, "An answer is required")
              return true
            }
          })
          if (errors.length) return

          try {
            props.setSnack({ show: true, variant: "info", message: `Saving game ${props.number}...`, autoHideDuration: 15000 })
            await Promise.all([
              api.upsertGame(data.game.game_id, data.game),
              api.upsertAgenda(data.agenda.agenda_id, data.agenda),
              ...data.prizeAllocations.map(pa => api.upsertPrizeAllocation(pa)),
            ])
            props.setSnack({ show: true, variant: "success", message: `Game ${props.number} saved`, autoHideDuration: 3000 })
            resetForm({ values: data })
            props.setData(data.game, data.agenda, data.prizeAllocations)
          } catch(err) {
            logger.error(err, "Failed to save game", { data })
            props.setSnack({ show: true, variant: "error", message: `Failed to save game ${props.number}`, autoHideDuration: 3000 })
          }
        }}
      >
        {({ values, handleSubmit, isSubmitting, dirty }) => (
          <form autoComplete="off" onSubmit={handleSubmit} className={classes.form}>
            <Grid container spacing={4} className={classes.container}>
              <Box className={classes.close} onClick={() => {
                if (!dirty || window.confirm(message)) {
                  props.closeModal()
                }
              }}><Close /></Box>
              <Grid item xs={12} sm={5}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel ref={sponsorLabel}>Sponsor</InputLabel>
                  <Field
                    name="game.sponsor_id"
                    as={Select}
                    labelId="sponsor-select"
                    labelWidth={sponsorLabelWidth}
                    disabled={isSubmitting}
                    displayEmpty
                  >
                    {props.sponsors.map(opt => <MenuItem key={opt.sponsor_id} value={opt.sponsor_id}>{opt.name}</MenuItem>)}
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5}>
                <FormControl variant="outlined" fullWidth style={{ background: '#FFF' }}>
                  <InputLabel ref={fanshoutsLabel}>Fanshouts</InputLabel>
                  <Field
                    name="game.fanshout_collections"
                    as={Select}
                    multiple
                    labelWidth={fanshoutLabelWidth}
                    disabled={isSubmitting}
                    value={values.game.fanshout_collections || []}
                  >
                    {props.fanshoutCollections.map(opt => <MenuItem key={opt.list_id} value={opt.list_id}>{opt.name}</MenuItem>)}
                  </Field>
                </FormControl>
                <Typography color="textPrimary" variant="caption">Tip: You can select multiple fanshout lists</Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel ref={languageLabel}>Language</InputLabel>
                  <Field
                    name="agenda.language"
                    as={Select}
                    labelId="language-select"
                    labelWidth={languageLabelWidth}
                    disabled={isSubmitting}
                    displayEmpty
                    value={values.agenda.language}
                  >
                    {["da", "en", "it"].map(opt => <MenuItem key={opt} value={opt}>{opt}</MenuItem>)}
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="agenda"
                  component={EditAgenda}
                  disabled={isSubmitting}
                />
              </Grid>

              <Grid item xs={12}>
                <hr style={{ margin: 0, padding: 0 }} />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="prizeAllocations"
                  component={GamePrizes}
                  prizes={props.prizes}
                  gameId={props.game.game_id}
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={12}>
                <hr style={{ marginTop: 0 }} />
              </Grid>
            </Grid>
            <Grid container justify="flex-end">
              <Button style={{ marginRight: 12 }} color="grey" onClick={() => {
                if (!dirty || window.confirm(message)) {
                  props.closeModal()
                }
              }}>Cancel</Button>
              <Button color="default" type="submit">Save game</Button>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  )
}

export default GameCardModal

