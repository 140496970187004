import React from 'react'
import { Button } from 'buttons'
import './list.scss'
import { RouteComponentProps } from 'react-router-dom'
import { usePrizeList } from 'react-http/results'
import uuid from 'uuid/v4'
import { Link, Redirect } from "react-router-dom"
import { Typography, Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@material-ui/core';

type Entry = {
  title: string
  type: string
  description: string
  image_url: string
  prize_id: string
}

type Props = {
  entries: Entry[]
  createPrize: () => void
}

const List = (props: Props) => {
  return (
    <>
      <Typography variant="h4" component="h1">
        <Box fontWeight="fontWeightMedium">Prizes</Box>
      </Typography>
      <hr />
      <Paper>
        <Box width={1} style={{ overflowX: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Prize title</TableCell>
                <TableCell>Prize description</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.entries.map(prize => (
                <TableRow hover key={prize.prize_id}>
                  <TableCell>{prize.title}</TableCell>
                  <TableCell>{prize.description}</TableCell>
                  <TableCell><img src={prize.image_url} height="60" /></TableCell>
                  <TableCell><Link to={`/service/prizes/${prize.prize_id}`}>edit</Link></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Paper>

      <div style={{ marginTop: 24, marginBottom: 24 }}>
        <Button onClick={props.createPrize} color="default" variant="contained">Create prize</Button>
      </div>
    </>
  )
}

export function PrizeList(props: RouteComponentProps) {
  const [prizes, error, loading] = usePrizeList()
  const [redirect, setRedirect] = React.useState(false)

  if (loading) {
    return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </div>
  }

  if (redirect) {
    return <Redirect to={`/service/prizes/${uuid()}`} />
  }

  if (error) {
    return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography component="h1" variant="body1">
        <Box whiteSpace="pre" textAlign="center">{`An error occured... \nWe couldn't retrieve your prizes.`}</Box>
      </Typography>
    </div>
  }

  const entries = prizes.map(p => ({
    prize_id: p.prize_id,
    title: p.title,
    type: "Prize",
    description: p.description,
    image_url: p.image_url
  }))

  return <>
    <List entries={entries} createPrize={() => setRedirect(true)} />
  </>
}
