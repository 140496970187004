import { User } from 'auth/types'
import http from '../http'

type Role = "admin" | "owner" | "organizer" | "publisher" | "player" | "user"


const getUserDetailsFromAuth = async (): Promise<User> => {
  try {
    const { data } = await http.get(`/auth/auth-me`);
    return data;
  } catch (e) {
    console.log("get user details error", e);
    if([401, 500].includes(e.response.status))
      window.location.href = '/unauthorized';
    return {
      access_token: '',
      expires_at: 0,
      id_token: '',
      token_type: '',
      scope: '',
      profile: {
        auth_time: 0,
        jti: '',
        owner_id: '',
        rat: 0,
        sub: ''
      }
    }
  }
}

export default { getUserDetailsFromAuth }
