import http from 'http/index'

export type Game = {
  game_id: string
  agenda_id: string
  sponsor_id: string
  fanshout_collections: string[]
  sponsor_name?: string
}
async function getGame(gameId: string) : Promise<Game> {
  try {
    const resp = await http.get(`/games/${gameId}`)

    return resp.data
  } catch (error) {
    throw error
  }
}

type GameConfiguration = {
  game_id: string
  agenda_id: string
  instance_id: string
  event_id: string
  game: {
    agenda: {
      tick: number
      tickspeed: { interval: number, unit: "ms" }
    }
  }
  timestamp: number
}

async function startGame(gameId: string, instanceId: string, data: GameConfiguration) : Promise<Game> {
  try {
    const resp = await http.put(`/games/${gameId}/instance/${instanceId}/play`, data)

    return resp.data
  } catch (error) {
    throw error
  }
}

async function getLeaderboard(game_id: string, instance_id: string): Promise<{ data: Leaderboard }> {
  return http.get(`/results/leaderboard/${game_id}/instance/${instance_id}`)
}

async function upsertGame(gameId: string, game: Game): Promise<{ data: Game }> {
  return http.put(`/games/${gameId}/`, game)
}

async function upsertAgenda(agendaId: string, agenda: Agenda): Promise<{ data: Agenda }> {
  return http.put(`/games/agendas/${agendaId}`, agenda)
}


async function updateFrameDuration(gameId: string, agendaId: string, frameId: string, duration: number) {
  return http.put(`/games/${gameId}/agendas/${agendaId}/frames/${frameId}/duration`, { duration })
}

async function getAgenda(agendaId: string): Promise<Agenda> {
  return http.get(`/games/agendas/${agendaId}`).then(res => res.data)
}

async function getGameInstances(gameId: string): Promise<GameInstance[]> {
  return http.get(`/games/${gameId}/instances`).then(res => res.data)
}

export interface Transition {
  type: string
  ticks: number
}

export interface Tickspeed {
  interval: number
  unit: string
}

export interface Frame<T> {
  frame_id: string
  progression_type: string
  ticks: number
  current?: number
  frame_type: FrameType
  transition?: Transition
  type: string
  data: T
}

export type Language = "da" | "en" | "it"

export interface Agenda {
  agenda_id: string
  tickspeed: Tickspeed
  frames: Frame<any>[]
  language?: Language
}

type QuizAnswer = {
  answer: {
    letter: string
    locale: "default"
    value: string
  }[]
  correct: boolean
  answer_id: string
}
type QuizQuestion = {
  locale: "default"
  value: string
}
export interface QuizData {
  answers: QuizAnswer[]
  question: QuizQuestion[]
}

export type ProgressionType = "time" | "action" | "redirect"
export type FrameType = "round" | "summary" | "pre_game" | "pre_round" | "finished" | "quit" | "breaker"

export type GameInstance = {
  instance_id: string
  event_id: string
  game_id: string
  agenda_id: string
  game: {
    agenda: Agenda
  }
  owner_id: string
  timestamp: number
}

type LeaderboardEntry = {
  user_id: string
  points: number
  username?: string
}

export type Leaderboard = {
  instance_id: string
  total: number
  leaderboard: LeaderboardEntry[]
}

export default {
  getGame,
  startGame,
  updateFrameDuration,
  getAgenda,
  upsertAgenda,
  upsertGame,
  getGameInstances,
  getLeaderboard,
}