import { useState, useEffect } from "react";
import api from 'api/index'
import { Agenda, Game, Frame, Transition, QuizData, GameInstance, Leaderboard } from 'api/games'
import uuid from "uuid/v4";
import logger from "xsided/xsided-logger-js";

type State = {
  agendas: (Agenda & { game_id: string })[]
  loading: boolean
  error?: API.Error
  saving: boolean
}

type UpdateFunc = {
  update: (agenda_id: string, duration: number) => Promise<void>
  saving: boolean
}

type Agendas = (Agenda & { game_id: string })[]

export function useAgendaByEvent(event_id: string): ReadWriteHook<Agendas, UpdateFunc, (agenda: Agenda) => Promise<Agenda>, API.Error> {
  const [state, setState] = useState<State>({ agendas: [], loading: true, saving: false })

  useEffect(() => {
    async function fetch() {
      try {
        const event = await api.getEvent(event_id)
        const games = await Promise.all(event.games.map(game_id => api.getGame(game_id)))
        const agendas = await Promise.all(games.map(async game => {
          const res = await api.getAgenda(game.agenda_id)
          return { ...res, game_id: game.game_id }
        }))
        setState(state => ({ ...state, agendas, loading: false }))
      } catch(e) {
        setState(state => ({ ...state, loading: false, error: e }))
        logger.error(e, "Failed to fetch agendas for event", { event_id })
      }
    }

    fetch()
  }, [event_id])

  const setCountdown = (agenda_id: string, duration: number) => {
    setState({ ...state, saving: true })
    const agenda = state.agendas.find(a => a.agenda_id === agenda_id)
    if (!agenda) {
      logger.error({}, "Attempted to update agenda not in state", { agenda_id })
      return Promise.resolve()
    }
    const frame = agenda.frames.find(f => f.type === "fanshout")
    if (!frame) {
      logger.error({}, "Couldn't find frame to update duration for", { agenda })
      return Promise.resolve()
    }

    return api.updateFrameDuration(agenda.game_id, agenda_id, frame.frame_id, duration)
    .then(() => {
      setState(state => ({
        ...state,
        saving: false,
        agendas: state.agendas.map(a => {
          if (a.agenda_id !== agenda_id) return a

          return {
            ...a,
            frames: a.frames.map(f => f.type !== "fanshout" ? f : { ...f, ticks: duration })
          }
        })
      }))
    })
    .catch(e => logger.error(e, "Failed to update frame duration"))
  }

  const saveAgenda = (agenda: Agenda) => api.upsertAgenda(agenda.agenda_id, agenda).then(res => res.data)

  return [state.agendas, { saving: state.saving, update: setCountdown }, saveAgenda, state.error, state.loading]
}

type SetGame = (game: Game) => void
type SaveGame = (game: Game) => Promise<Game>
export function useGame(game_id: string): ReadWriteHook<Game, SetGame, SaveGame, API.Error> {
  type State = { game: Game, loading: boolean, error?: API.Error }
  const [state, setState] = useState<State>({ game: { game_id, sponsor_id: "", sponsor_name: "", agenda_id: "", fanshout_collections: [] }, loading: false })

  useEffect(() => {
    async function fetch() {
      try {
        setState({ ...state, loading: true })
        const res = await api.getGame(game_id)
        let game = res
        if (!game.fanshout_collections) {
          game.fanshout_collections = []
        }
        setState({ ...state, game: res, loading: false, error: null })
      } catch(e) {
        logger.error(e, "Failed to fetch game", { game_id })
        setState({ ...state, error: e, loading: false })
      }
    }
    fetch()
  }, [game_id])

  const setGame = (game: Game) => {
    setState({ ...state, game })
  }
  const saveGame = async (game: Game) => api.upsertGame(game.game_id, game).then(res => res.data)

  return [state.game, setGame, saveGame, state.error, state.loading]
}

type SetAgenda = (agenda: Agenda) => void
type SaveAgenda = (agenda: Agenda) => Promise<Agenda>
type UseAgendaState = {
  agenda: Agenda
  loading: boolean
  error?: API.Error
  setAgenda: SetAgenda
  saveAgenda: SaveAgenda
  updateFrameDuration: (game_id: string, agenda_id: string, frame_id: string, duration: number) => Promise<any>
}

export function useLeaderboard(game_id: string, instance_id: string) {
  type State = { leaderboard: Leaderboard, error?: boolean, loading: boolean }
  const [state, setState] = useState<State>({ leaderboard: { instance_id: "", total: 0, leaderboard: [] }, loading: false })

  useEffect(() => {
    async function fetch() {
      try {
        setState({ ...state, loading: true, error: false })
        const res = await api.getLeaderboard(game_id, instance_id)
        setState({ ...state, leaderboard: res.data, loading: false})
      } catch(e) {
        logger.error(e, "Failed to fetch leaderboard", { game_id, instance_id })
        setState({ ...state, error: true, loading: false })
      }
    }
    fetch()
  }, [game_id, instance_id])

  return state
}

export function useAgenda(agendaId: string): UseAgendaState {
  type State = Pick<UseAgendaState, 'agenda' | 'loading' | 'error'>
  const defaultAgenda = { agenda_id: "", tickspeed: { interval: 1000, unit: "ms" }, frames: [] }
  const [state, setState] = useState<State>({ agenda: defaultAgenda, loading: false })

  useEffect(() => {
    if (!agendaId) return
    async function fetch() {
      try {
        setState({ ...state, loading: true })
        const agenda = await api.getAgenda(agendaId)
        setState({ agenda, loading: false, error: null })
      } catch(e) {
        logger.error(e, "Failed to fetch agenda")
        alert('Failed to fetch agenda')
        setState({ ...state, error: e, loading: false })
      }
    }
    fetch()
  }, [agendaId])

  const setAgenda = (agenda: Agenda) => {
    setState({ ...state, agenda })
  }

  const saveAgenda = async (agenda: Agenda): Promise<Agenda> => {
    return api.upsertAgenda(agenda.agenda_id, agenda).then(res => res.data)
  }

  const updateFrameDuration = (game_id: string, agenda_id: string, frame_id: string, duration: number) => {
    return api.updateFrameDuration(game_id, agenda_id, frame_id, duration)
    .then(() => {
      const frameIndex = state.agenda.frames.findIndex(f => f.frame_id === frame_id)
      const frames = [...state.agenda.frames]
      frames[frameIndex] = { ...frames[frameIndex], ticks: duration }

      setState({
        ...state,
        agenda: {
          ...state.agenda,
          frames,
        }
      })
    })
  }

  return { ...state, setAgenda, saveAgenda, updateFrameDuration }
}

export function useGameInstances(game_id: string): ReadHook<GameInstance[], API.Error> {
  type State = { instances: GameInstance[], error?: API.Error, loading: boolean }
  const [state, setState] = useState<State>({ instances: [], loading: false })

  useEffect(() => {
    async function fetch() {
      try {
        setState({ ...state, loading: true })
        const gameInstances = await api.getGameInstances(game_id)
        setState({ ...state, instances: gameInstances, loading: false })
      } catch(e) {
        setState({ ...state, loading: false, error: e })
        logger.error(e, "Failed to fetch game instances")
      }
      // const prizeAllocations = await api.listPrizeAllocations(game_id)
      // console.log('asdasd', { gameInstances, prizeAllocations })
      // const x = await Promise.all(
      //   gameInstances.map(async gi => {
      //     return Promise.all(
      //       prizeAllocations.map(pa => api.listSomething(pa.allocation_id, game_id, gi.instance_id))
      //     )
      //   })
      // )
    }
    fetch()
  }, [game_id])

  return [state.instances, state.error, state.loading]
}

export const roundFrames = (): Frame<any>[] => {
  const roundId = uuid()
  return [
    {
      data: {},
      frame_id: uuid(),
      frame_type: "pre_round",
      progression_type: "time",
      ticks: 3,
      transition: {
        ticks: 0.5,
        type: "fade"
      },
      type: "round_number"
    },
    {
      "data": {
        "answers": [
          {
            "answer": [
              {
                "letter": "A",
                "locale": "default",
                "value": ""
              }
            ],
            "answer_id": uuid(),
            "correct": false
          },
          {
            "answer": [
              {
                "letter": "B",
                "locale": "default",
                "value": ""
              }
            ],
            "answer_id": uuid(),
            "correct": false
          },
          {
            "answer": [
              {
                "letter": "C",
                "locale": "default",
                "value": ""
              }
            ],
            "answer_id": uuid(),
            "correct": false
          },
          {
            "answer": [
              {
                "letter": "D",
                "locale": "default",
                "value": ""
              }
            ],
            "answer_id": uuid(),
            "correct": false
          }
        ],
        "duration": 10,
        "question": [
          {
            "locale": "default",
            "value": ""
          }
        ]
      },
      "frame_id": roundId,
      "frame_type": "round",
      "progression_type": "time",
      "ticks": 11,
      "transition": {
        "ticks": 1,
        "type": "slide-right"
      },
      "type": "quiz"
    },
    {
      "data": {
        "summary_for": roundId
      },
      "frame_id": uuid(),
      "frame_type": "summary",
      "progression_type": "time",
      "ticks": 8,
      "transition": {
        "ticks": 1,
        "type": "slide-right"
      },
      "type": "quiz_summary"
    }
  ]
}