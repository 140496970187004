import React from 'react'

type Option = {
  key: string
  value: string
  text: string
  disabled?: boolean
}

interface DropdownProps extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>{
  options: Option[]
  defaultOptionText: string
}

const Dropdown: React.FC<DropdownProps> = ({ options, defaultOptionText, ...rest }) => {
  if (!rest.value) {
    options.unshift({ key: "default", value: "", text: defaultOptionText, disabled: true })
  }

  return (
    <select {...rest} value={rest.value ? rest.value : ""} style={{ width: '100%' }}>
      {options.map(opt => <option disabled={opt.disabled} key={opt.key} value={opt.value}>{opt.text}</option>)}
    </select>
  )
}

interface DropdownWithLabelProps extends DropdownProps{
  label: string
}

export const DropdownWithLabel: React.FC<DropdownWithLabelProps> = ({ label, ...rest }) => {
  return (
    <div>
      <label>{label}</label>
      <Dropdown {...rest} />
    </div>
  )
}