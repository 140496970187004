import * as React from 'react'
import { Category } from '../api/categories'
import { Button } from 'buttons'
import { useCategory } from 'react-http/categories'
import { RouteComponentProps, Prompt } from 'react-router-dom'
import { Breadcrumbs, CrumbLink, Crumb } from '../breadcrumbs'
import { Typography, Box, CircularProgress, TextField, Grid } from '@material-ui/core'
import { Formik, Field } from 'formik'
import { XsidedSnackbar, XsidedSnackProps } from 'snackbar'
import logger from 'xsided/xsided-logger-js'
import { message } from 'forms/prompt-text'

type Props = {
  category: Category
  saveCategory: (category: Category) => Promise<Category>
}

const Details = (props: Props) => {
  const [snack, setSnack] = React.useState<XsidedSnackProps>({ show: false, variant: "success", message: "Prize saved", autoHideDuration: 3000 });

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <CrumbLink href="/service/categories">Categories</CrumbLink>
        <Crumb>Category</Crumb>
      </Breadcrumbs>
      <Typography variant="h4" component="h1">
        <Box fontWeight="fontWeightMedium">Category</Box>
      </Typography>
      <hr />
      <Formik
        initialValues={props.category}
        onSubmit={async (data, { resetForm }) => {
          try {
            setSnack({ show: true, variant: "info", message: "Saving...", autoHideDuration: null })
            const category = await props.saveCategory(data)
            setSnack({ show: true, variant: "success", message: "Category saved", autoHideDuration: 3000 })
            resetForm({ values: category })
          } catch(err) {
            logger.error(err, "Failed to save category", { data })
            setSnack({ show: true, variant: "error", message: "Failed to save category", autoHideDuration: null })
          }
        }}
      >
        {({ handleSubmit, isSubmitting, dirty }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Prompt
              when={dirty}
              message={message}
            />
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name="name"
                  label="Category name"
                  type="text"
                  variant="outlined"
                  disabled={isSubmitting}
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  style={{ float: 'right' }}
                  disabled={isSubmitting || !dirty}
                  color="default"
                  variant="contained"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <XsidedSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        show={snack.show}
        autoHideDuration={snack.autoHideDuration}
        onClose={() => setSnack(snack => ({ ...snack, show: false }))}
        variant={snack.variant}
        message={snack.message}
      />
    </>
  )
}

const CategoryDetails = (props: RouteComponentProps<{ category_id: string }>) => {
  const [category, saveCategory, error, loading] = useCategory(props.match.params.category_id)

  if (loading) {
    return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </div>
  }

  if (error && !(error.response && error.response.status === 404)) {
    return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography component="h1" variant="body1">
        <Box whiteSpace="pre" textAlign="center">{`An error occured... \nWe couldn't retrieve your category.`}</Box>
      </Typography>
    </div>
  }

  return (
    <Details
      category={category}
      saveCategory={saveCategory}
    />
  )
}

export default CategoryDetails