import * as React from 'react'
import { Category } from 'api/categories'
import { useCategories } from 'react-http/categories'
import { RouteComponentProps } from 'react-router'
import uuid from 'uuid/v4'
import { Button } from 'buttons'
import { Typography, Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@material-ui/core';
import { Link, Redirect } from "react-router-dom"

type Props = {
  categories: Category[]
  createCategory: () => void
}

const List = (props: Props) => {
  return (
    <>
      <Typography variant="h4" component="h1">
        <Box fontWeight="fontWeightMedium">Categories</Box>
      </Typography>
      <hr />
      <Paper>
        <Box width={1} style={{ overflowX: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Category name</TableCell>
                <TableCell></TableCell>
                <TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.categories.map(category => (
                <TableRow hover key={category.category_id}>
                  <TableCell>{category.name}</TableCell>
                  <TableCell></TableCell>
                  <TableCell><Link to={`/service/categories/${category.category_id}`}>edit</Link></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Paper>

      <div style={{ marginTop: 24, marginBottom: 24 }}>
        <Button onClick={props.createCategory} color="default" variant="contained">Create category</Button>
      </div>
    </>
  )
}
const CategoryList = (props: RouteComponentProps) => {
  const [categories, error, loading] = useCategories()

  const addCategory = () => {
    props.history.push(`/service/categories/${uuid()}`)
  }

  if (loading) {
    return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </div>
  }

  if (error) {
    return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography component="h1" variant="body1">
        <Box whiteSpace="pre" textAlign="center">{`An error occured... \nWe couldn't retrieve your categories.`}</Box>
      </Typography>
    </div>
  }

  return (
    <List
      createCategory={addCategory}
      categories={categories}
    />
  )
}

export default CategoryList