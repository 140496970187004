import http from 'http/index'

export type Event = {
  event_id: string
  title: string
  category: string
  category_id: string
  end_date: number
  start_date: number
  description: string
  code: string
  games: string[]
  hero_image_url?: string
  quiz_type?: string
}

async function saveEvent(event: Event): Promise<{ data: Event }> {
  return http.put(`/events/${event.event_id}`, event)
}

async function getEventList() : Promise<Event[]> {
  try {
    const resp = await http.get("/events/organizer/events")

    return resp.data
  } catch (error) {
    throw error
  }
}

async function getEvent(eventId: string) : Promise<Event> {
  try {
    const resp = await http.get(`/events/${eventId}`)

    return resp.data
  } catch (error) {
    throw error
  }
}

export function getEventCode(): Promise<string> {
  return http.get("/events/code").then(res => res.data)
}

export default { saveEvent, getEventList, getEvent, getEventCode }