import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Button } from 'buttons'
import { FanshoutCollection } from 'api/fanshouts'
import uuid from 'uuid/v4'
import { useFanshoutCollectionList } from 'react-http/fanshouts'
import './list.scss'
import { Typography, Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@material-ui/core';
import { Link, Redirect } from "react-router-dom"

type Props = {
  createFanshoutCollection: () => void
  entries: FanshoutCollection[]
}

const List = (props: Props) => {
  return (
    <>
      <Typography variant="h4" component="h1">
        <Box fontWeight="fontWeightMedium">Fanshouts</Box>
      </Typography>
      <hr />

      <Paper>
        <Box width={1} style={{ overflowX: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Collection name</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.entries.map(collection => (
                <TableRow hover key={collection.list_id}>
                  <TableCell>{collection.name}</TableCell>
                  <TableCell>{collection.fanshouts.length}</TableCell>
                  <TableCell><Link to={`/service/fanshouts/${collection.list_id}`}>edit</Link></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Paper>

      <div style={{ marginTop: 24, marginBottom: 24 }}>
        <Button onClick={props.createFanshoutCollection} color="default" variant="contained">Create collection</Button>
      </div>
    </>
  )
}

export function FanshoutCollectionList(props: RouteComponentProps) {
  const [collections, error, loading] = useFanshoutCollectionList()
  const [redirect, setRedirect] = React.useState(false)

  if (loading) {
    return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </div>
  }

  if (redirect) {
    return <Redirect to={`/service/fanshouts/${uuid()}`} />
  }

  if (error) {
    return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography component="h1" variant="body1">
        <Box whiteSpace="pre" textAlign="center">{`An error occured... \nWe couldn't retrieve your fanshout collections.`}</Box>
      </Typography>
    </div>
  }

  return <>
    <List
      createFanshoutCollection={() => setRedirect(true)}
      entries={collections}
    />
  </>
}