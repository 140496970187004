import React from 'react'
import { Trapezoid } from 'trapezoid/trapezoid'
import { useGame, useAgenda } from 'react-http/games'
import { Agenda, Game } from 'api/games'
import { useHistory } from 'react-router-dom'
import { usePrizeAllocationList, usePrizeList } from 'react-http/results'
import { useFanshoutCollectionList } from 'react-http/fanshouts'
import { useSponsorList } from 'react-http/sponsors'
import { FanshoutCollection } from 'api/fanshouts'
import { Skeleton } from '@material-ui/lab'
import { Grid, makeStyles, Box, Typography, Modal } from '@material-ui/core'
import { Sponsor } from 'api/sponsors'
import { PrizeAllocation, Prize } from 'api/results'
import { Button } from 'buttons'
import { XsidedSnackbar, XsidedSnackProps } from 'snackbar'
import GameCardModal from './card-modal'
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    margin: theme.spacing(4, 4, 3),
    padding: theme.spacing(2, 4, 3),
    flexGrow: 1,
    overflow: 'scroll',
    height: '90%'
  },
  form: {
    flexGrow: 1,
  },
  box: {
    padding: theme.spacing(2),
    backgroundColor: "#f3f4f9",
    border: "2px solid #cfdae9",
    borderRadius: 4,
    position: 'relative',
  },
  box_header: {
    marginBottom: 8,
  },
  quiz_info: {
    display: 'flex',
  },
  questions_amount: {
    width: 32,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "#000",
    color: '#FFF'
  },
  questions_text: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    backgroundColor: '#FFF',
  },
  white: {
    color: '#FFF',
  },
  submit: {
    float: 'right',
    marginTop: theme.spacing(4),
  }
}));

type CardContentProps = {
  sponsors: Sponsor[]
  game: Game
  number: number
  agenda: Agenda
  fanshoutCollections: FanshoutCollection[]
  prizeAllocations: PrizeAllocation[]
  prizes: Prize[]
  goToPrizes: () => void
  goToGame: () => void
  saveGame: (game: Game) => Promise<Game>
  saveAgenda: (agenda: Agenda) => Promise<Agenda>
  setData: (game: Game, agenda: Agenda, prizeAllocations: PrizeAllocation[]) => void
}

const CardContent = (props: CardContentProps) => {
  const classes = useStyles()
  const [snack, setSnack] = React.useState<XsidedSnackProps>({ show: false, variant: "success", message: "Game saved", autoHideDuration: 3000 });
  const [open, setOpen] = React.useState(false)
  const sponsor = props.sponsors.find(s => s.sponsor_id === props.game.sponsor_id)
  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Box className={classes.box}>
            <Typography component="h3" variant="h6" className={classes.box_header}>
              <Box fontWeight="fontWeightBold">Sponsor</Box>
            </Typography>
            <Box style={{ background: '#FFF', marginBottom: 12, padding: 8 }}>
              <Typography>
                {sponsor ?
                  <Box component="span" fontWeight="fontWeightBold">
                    {sponsor.name}
                  </Box>
                  :
                  "No sponsor selected"
                }
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className={classes.box}>
            <Typography component="h3" variant="h6" className={classes.box_header}>
              <Box fontWeight="fontWeightBold">Fanshouts</Box>
            </Typography>
            <Box style={{ background: '#FFF', marginBottom: 12, padding: 8 }}>
              <Typography>
                {
                  props.game.fanshout_collections.length === 0 ?
                  "No fanshouts selected"
                  :
                  <Box component="span" fontWeight="fontWeightBold">
                    {props.game.fanshout_collections.map(cId => {
                      const collection = props.fanshoutCollections.find(c => c.list_id === cId)
                      if (!collection) return ""
                      return collection.name
                    }).join(", ")}
                  </Box>
                }
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className={classes.box}>
            <Typography component="h3" variant="h6" className={classes.box_header}>
              <Box fontWeight="fontWeightBold">Quiz content</Box>
            </Typography>
            <Box className={classes.quiz_info}>
              <Box className={classes.questions_amount}><Typography color="inherit">{props.agenda.frames.filter(f => f.type === "quiz").length}</Typography></Box>
              <Box className={classes.questions_text}><Typography>Questions in total</Typography></Box>
            </Box>
            <br />
            <Typography>Language: {props.agenda.language}</Typography>
          </Box>
        </Grid>
        <Grid container item xs={12} sm={6} direction="column" justify="space-between">
          <Box className={classes.box}>
            <Typography component="h3" variant="h6" className={classes.box_header}>
              <Box fontWeight="fontWeightBold">Prizes</Box>
            </Typography>
            {
              props.prizeAllocations.length === 0 ?
              "No prizes selected"
              :
              props.prizeAllocations.map(allo => (
                <Box key={allo.allocation_id} style={{ background: '#FFF', marginBottom: 12, padding: 8 }}>
                  <Typography>
                    <Box component="span" fontWeight="fontWeightBold">
                      {allo.prize.title}
                    </Box> x{allo.range.Max + 1 - allo.range.Min}
                  </Typography>
                </Box>
              ))
            }
          </Box>
          <Grid item xs={false}>
            <Box style={{ float: 'right', marginTop: 12 }}>
              <Button color="default" onClick={openModal}>Edit game</Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <Modal disableBackdropClick disableEscapeKeyDown open={open} onClose={closeModal}>
          <div className={classes.paper}>
            <GameCardModal
              agenda={props.agenda}
              closeModal={closeModal}
              number={props.number}
              game={props.game}
              fanshoutCollections={props.fanshoutCollections}
              sponsors={props.sponsors}
              prizeAllocations={props.prizeAllocations}
              prizes={props.prizes}
              setSnack={setSnack}
              setData={props.setData}
            />
          </div>
        </Modal>
      </Grid>
      <XsidedSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        show={snack.show}
        autoHideDuration={snack.autoHideDuration}
        onClose={() => setSnack(snack => ({ ...snack, show: false }))}
        variant={snack.variant}
        message={snack.message}
      />
    </>
  )
}

type CardProps = {
  title: string
  children: React.ReactNode
}

function Card(props: CardProps) {
  return (
    <div style={{ marginBottom: 32 }}>
      <Trapezoid backgroundColor="#101531">
        <h2 className="white">{props.title}</h2>
      </Trapezoid>
      <div>
        <div style={{ padding: '32px 24px', boxShadow: '0px 0px 45px -21px rgba(0,0,0,0.69)' }}>
          {props.children}
        </div>
      </div>
    </div>
  )
}

type Props = {
  eventId: string
  gameId: string
  number: number
}

export function GameCard(props: Props) {
  const [game, setGame, saveGame, gameError, gameLoading] = useGame(props.gameId)
  const agenda = useAgenda(game.agenda_id)
  const [sponsors, sponsorListError, sponsorListLoading] = useSponsorList()
  const [collections, fanshoutsError, fanshouts_loading] = useFanshoutCollectionList()
  const [prizeAllocations, setAllocations, , prize_allocations_error, prize_allocations_loading] = usePrizeAllocationList(props.gameId)
  const [prizes, prizeListError, prizeListLoading] = usePrizeList()
  const history = useHistory()

  const setData = (game: Game, ag: Agenda, prizeAllocations: PrizeAllocation[]) => {
    setGame(game)
    agenda.setAgenda(ag)
    setAllocations(prizeAllocations)
  }

  const goToPrizes = () => history.push(`/service/events/${props.eventId}/games/${props.gameId}/prizes`)
  const goToGame = () => history.push(`/service/events/${props.eventId}/games/${props.gameId}/agendas/${agenda.agenda.agenda_id}`)

  if (gameLoading || agenda.loading || sponsorListLoading || fanshouts_loading || prize_allocations_loading || prizeListLoading) {
    return <Card title={`Quiz ${props.number}`}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="rect" width="100%" height={50} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="rect" width="100%" height={50} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="rect" width="100%" height="300px"  />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="rect" width="100%" height="300px"  />
        </Grid>
      </Grid>
    </Card>
  }

  if (gameError || agenda.error || sponsorListError || fanshoutsError || prize_allocations_error || prizeListError) {
    return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography component="h1" variant="body1">
        <Box whiteSpace="pre" textAlign="center">{`An error occured... \nWe couldn't retrieve your game data.`}</Box>
      </Typography>
    </div>
  }

  return <Card title={`Quiz ${props.number}`}>
    <CardContent
      sponsors={sponsors}
      game={game}
      agenda={agenda.agenda}
      number={props.number}
      fanshoutCollections={collections}
      prizeAllocations={prizeAllocations}
      goToPrizes={goToPrizes}
      goToGame={goToGame}
      saveGame={saveGame}
      saveAgenda={agenda.saveAgenda}
      prizes={prizes}
      setData={setData}
    />
  </Card>

}
