import AWS from 'aws-sdk'
import Logger from 'xsided/xsided-logger-js';

AWS.config.update({
  accessKeyId: "AKIAXBAAPIT5K5QX5P5I",
  secretAccessKey: "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"
})
AWS.config.region = "eu-west-1";

const S3 = new AWS.S3()

async function uploadImage(file: File, path: string, owner_id: string): Promise<{ path: string }> {
  if (!owner_id) throw Error("Invalid owner")
  if (!file.type.startsWith("image/")) throw Error("Invalid file type")

  const fileExtension = file.type.replace("image/", "")
  const filename = Math.floor(Date.now() / 1000) + `-${path}.${fileExtension}`
  const key = `${owner_id}/${filename}`

  const params = {
    Body: file,
    Bucket: "cdn.xsided.io",
    Key: key
  }
  try {
    await S3.putObject(params).promise()
  } catch(e) {
    Logger.error(e, "Failed to upload image")
    throw Error('Image upload failed.')
  }
  return { path: `https://s3-eu-west-1.amazonaws.com/cdn.xsided.io/${key}` }
}

export {}
export default { uploadImage }