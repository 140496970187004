import React from 'react';
import Router from "./routes"
import "./App.css"
import "./fonts.css"
import Logger from 'xsided/xsided-logger-js'
import { CssBaseline, ThemeProvider, createMuiTheme } from '@material-ui/core';

Logger.app_version = "1.0.8"
Logger.output = msg => {
  if ((window as any)["_LTracker"] && process.env.NODE_ENV !== "development") {
    (window as any)["_LTracker"].push(msg);
  } else {
    console.log("Logger", msg);
  }
};

const theme = createMuiTheme({
  typography: {
    "fontFamily": "Gilroy",
    "fontWeightLight": 500,
    "fontWeightRegular": 500,
    "fontWeightMedium": 800,
    "fontWeightBold": 900,
  },
  palette: {
    text: {
      primary: "#292c38",
    }
  }
})

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Router />
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
