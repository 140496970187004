import * as React from 'react'

type Props = {

}

const Page404 = (props: Props) => {
  return (
    <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <h3>The requested route couldn't be found. </h3>
      <h5>Please make sure that the path is correct and that you have the right permissions</h5>
      <p>If you think this is a mistake, please contact Xsided at <a href="mailto:contact@xsided.com">contact@xsided.com</a>  </p>
    </div>
  )
}

export default Page404