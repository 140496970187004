import http from '../http'

export type Category = {
  category_id: string
  name: string
}

async function listCategories() {
  return http.get('/categories/').then(res => res.data)
}

async function getCategory(category_id: string): Promise<Category> {
  return http.get(`/categories/${category_id}`).then(res => res.data)
}

async function upsertCategory(category: Category): Promise<Category> {
  return http.put(`/categories/${category.category_id}`, category).then(res => res.data)
}

export default {
  listCategories,
  getCategory,
  upsertCategory,
}
