import http from '../http/index'

export type Fanshout = {
  fanshout_id: string
  value: string
}

export type FanshoutCollection = {
  list_id: string
  name: string
  fanshouts: Fanshout[]
}

async function getFanshoutCollection(list_id: string): Promise<FanshoutCollection> {
  return http.get(`/fanshouts/${list_id}`).then(res => res.data)
}

async function listFanshoutCollections(): Promise<FanshoutCollection[]> {
  return http.get(`/fanshouts`).then(res => res.data)
}

async function upsertFanshoutCollection(list_id: string, collection: FanshoutCollection): Promise<FanshoutCollection> {
  return http.put(`/fanshouts/${list_id}`, collection).then(res => res.data)
}

export default { listFanshoutCollections, getFanshoutCollection, upsertFanshoutCollection }
