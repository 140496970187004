import React, { useState, useEffect } from "react";
import api from 'api/index'
import { Event } from 'api/events'
import uuid from "uuid/v4";
import { Frame } from "api/games";
import logger from "xsided/xsided-logger-js";

export type SetEvent = (event: Event) => void
export type SaveEvent = (event: Event) => Promise<Event>

export function useEventList() : ReadHook<Event[], API.Error> {
  type State = { events: Event[], loading: boolean, error?: API.Error }
  const [state, setState] = useState<State>({ events: [], loading: true })

  useEffect(() => {
    const fetch = async () => {
      try {
        const resp = await api.getEventList()
        const events = resp.sort((a, b) => a.start_date - b.start_date)
        setState({ ...state, events, loading: false })
      } catch(error) {
        setState({ ...state, error: error, loading: false })
      }
    }

    fetch()
  }, [])

  return [state.events, state.error, state.loading]
}

type UseEventValue = {
  event: Event
  setEvent: SetEvent
  saveEvent: SaveEvent
  error?: API.Error
  loading: boolean
  createGame: () => Promise<string>
}
export function useEvent(eventId: string): UseEventValue {
  type State = { event: Event, loading: boolean, error?: API.Error }
  const [state, setState] = useState<State>({ event: { event_id: eventId, start_date: Math.floor(+new Date() / 1000), end_date: Math.floor((+new Date() / 1000)), games: [], category_id: "", category: "", code: "", title: "", description: "" }, loading: true })

  const setEvent = (event: Event) => {
    setState({ ...state, event })
  }

  const saveEvent = (event: Event): Promise<Event> => api.saveEvent(event).then(res => res.data)

  const createGame = async(): Promise<string> => {
    const gameId = uuid()
    const agendaId = uuid()

    await api.upsertAgenda(agendaId, {
      "agenda_id": agendaId,
      "tickspeed": {
          "interval": 1000,
          "unit": "ms"
      },
      "frames": baseAgendaFrames,
      "language": "da"
    })
    await api.upsertGame(gameId, { game_id: gameId, sponsor_id: "", agenda_id: agendaId, fanshout_collections: [] })
    return gameId
  }

  useEffect(() => {
    const fetch = async () => {
      try {
        const resp = await api.getEvent(eventId)
        setState({ ...state, event: resp, loading: false })
      } catch(error) {
        setState({ ...state, error: error, loading: false })
      }
    }

    fetch()
  }, [eventId])

  return { ...state, createGame, saveEvent, setEvent }
}

const baseAgendaFrames: Frame<any>[] = [
  {
    "data": {},
    "frame_id": uuid(),
    "frame_type": "pre_game",
    "progression_type": "time",
    "ticks": 180,
    "transition": {
      "ticks": 1,
      "type": "fade"
    },
    "type": "fanshout"
  },
  {
    "data": {},
    "frame_id": uuid(),
    "frame_type": "pre_game",
    "progression_type": "time",
    "ticks": 5,
    "transition": {
      "ticks": 1,
      "type": "fade"
    },
    "type": "countdown"
  },
  {
    "data": {},
    "frame_id": uuid(),
    "frame_type": "breaker",
    "progression_type": "time",
    "ticks": 20,
    "transition": {
      "ticks": 1,
      "type": "fade"
    },
    "type": "winner"
  },
  {
    "data": {},
    "frame_id": uuid(),
    "frame_type": "finished",
    "progression_type": "redirect",
    "ticks": 1,
    "type": "finished"
  },
  {
    "data": {},
    "frame_id": uuid(),
    "frame_type": "quit",
    "progression_type": "redirect",
    "ticks": 1,
    "type": "redirect"
  }
]