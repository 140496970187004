import React, { useEffect, useState } from 'react'
import { Prize, PrizeAllocation, UserPrize } from 'api/results'
import api from 'api'
import logger from 'xsided/xsided-logger-js'

type PrizeListState = {
  prizes: Prize[]
  loading: boolean
  error?: API.Error
}

type State = {
  prizeAllocations: PrizeAllocation[]
  loading: boolean
  error?: API.Error
}
export function usePrizeAllocationList(source: string): ReadWriteHook<PrizeAllocation[], (prizeAllocations: PrizeAllocation[]) => void, (prizeAllocations: PrizeAllocation[]) => Promise<any>, API.Error> {
  const [state, setState] = useState<State>({ prizeAllocations: [], loading: false })

  useEffect(() => {
    async function fetch() {
      try {
        setState(state => ({ ...state, loading: true }))
        const prizeAllocations = await api.listPrizeAllocations(source)
        setState(state => ({ ...state, prizeAllocations, loading: false }))
      } catch(e) {
        logger.error(e, "Failed to fetch prize allocation list", { source })
        alert('Failed to fetch prize allocation list')
      }
    }
    fetch()
  }, [source])

  const setAllocations = (prizeAllocations: PrizeAllocation[]) => {
    setState(state => ({ ...state, prizeAllocations }))
  }

  const saveAllocations = (prizeAllocations: PrizeAllocation[]) => {
    return Promise.all(prizeAllocations.map(allo => api.upsertPrizeAllocation(allo)))
  }

  return [state.prizeAllocations, setAllocations, saveAllocations, state.error, state.loading]
}

export function usePrizeList(): ReadHook<Prize[], API.Error> {
  const [state, setState] = useState<PrizeListState>({ prizes: [], loading: false, error: null })

  useEffect(() => {
    async function fetch() {
      setState(state => ({ ...state, loading: true, error: null }))
      try {
        const res = await api.getPrizeList()
        setState({ prizes: res, loading: false, error: null })
      } catch(e) {
        setState({ prizes: [], loading: false, error: e })
        logger.error(e, 'Failed to fetch prize list')
      }
    }

    fetch()
  }, [])

  return [state.prizes, state.error, state.loading]
}

type PrizeState = {
  prize: Prize,
  loading: boolean
  error?: API.Error
}

export function usePrize(prize_id: string): ReadWriteHook<Prize, (key: string, value: any) => void, (prize: Prize) => Promise<Prize>, API.Error> {
  const [state, setState] = useState<PrizeState>({ prize: { prize_id, image_url: "", description: "", timestamp: Math.floor(Date.now() / 1000), title: "" }, loading: true })

  const updateValue = (key: string, value: string) => {
    setState(state => ({ ...state, prize: { ...state.prize, [key]: value }}))
  }

  const savePrize = (prize: Prize) => api.upsertPrize(prize.prize_id, prize).then(res => res.data)

  useEffect(() => {
    async function fetch() {
      try {
        const res = await api.getPrize(prize_id)
        setState({ prize: res, loading: true, error: null })
        setState(p => ({ ...p, loading: false}))
      } catch(e) {
        logger.error(e, 'Failed to fetch prize', { prize_id })
        setState({ ...state, loading: false, error: e })
      }
    }
    fetch()
  }, [prize_id])

  return [state.prize, updateValue, savePrize, state.error, state.loading]
}

export function useUserPrizeForGameInstance(allocation_id: string, game_id: string, instance_id: string): ReadHook<UserPrize[], API.Error> {
  type State = { userPrizes: UserPrize[], loading: boolean, error?: API.Error }
  const [state, setState] = useState<State>({ userPrizes: [], loading: false })

  useEffect(() => {
    async function fetch() {
      try {
        setState({ ...state, loading: true })
        const userPrizes = await api.listSomething(allocation_id, game_id, instance_id)
        setState({ ...state, userPrizes, loading: false })
      } catch(e) {
        logger.error(e, 'Failed to list user prizes for instance', { allocation_id, game_id, instance_id })
        setState({ ...state, error: e })
      }
    }
    fetch()
  }, [allocation_id, game_id, instance_id])

  return [state.userPrizes, state.error, state.loading]
}