import http from '../http/index'

export type Prize = {
  prize_id: string
  image_url: string
  description: string
  timestamp: number
  title: string
}

async function getPrizeList(): Promise<Prize[]> {
  try {
    const resp = await http.get("/results/prizes")

    return resp.data
  } catch (error) {
    throw error
  }
}

async function getPrize(prizeId: string): Promise<Prize> {
  try {
    const resp = await http.get(`/results/prizes/${prizeId}`)

    return resp.data
  } catch (error) {
    throw error
  }
}

async function upsertPrize(prizeId: string, prize: Prize): Promise<{ data: Prize }> {
  return http.put(`/results/prizes/${prizeId}`, prize)
}

type HandoutMethod = {
  description: string
  type: "redeem"
}

type Range = {
  Max: number
  Min: number
}
type GameID = string

export type PrizeAllocation = {
  allocation_id: string
  handout_method: HandoutMethod
  owner_id: string
  prize: Prize
  range: Range
  sort: number
  type: "prize"
  valid_until: number
  prize_id: string
  parent_id: GameID
}

export type UserPrize = {
  owner_id: string
  user_prize_id: string
  instance_id: string
  game_id: string
  event_id: string
  prize_id: string
  prize: {
    prize_id: string
    image_url: string
    description: string
    title: string
  }
  user_id: string
  timestamp: number
  valid_until: number
  handout_method: {
    type: "redeem" | "collect"
    title: string
    status?: "REDEEMED"
  }
  allocation_id: string
  sponsor_id: string
  type: "prize" | "goodiebag"
}

async function upsertPrizeAllocation(prizeAllocation: PrizeAllocation) {
  return http.put(`/results/prize-allocation/${prizeAllocation.allocation_id}`, prizeAllocation)
}

async function listPrizeAllocations(source: string): Promise<PrizeAllocation[]> {
  return http.get(`/results/prize-allocations/source/${source}`).then(res => res.data)
}

async function listSomething(allocation_id: string, game_id: string, instance_id: string): Promise<UserPrize[]> {
  return http.get(`/results/prize-allocations/${allocation_id}/game/${game_id}/instance/${instance_id}`).then(res => res.data)
}

export default { getPrizeList, getPrize, upsertPrize, upsertPrizeAllocation, listPrizeAllocations, listSomething }
