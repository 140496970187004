import * as React from 'react'
import { Breadcrumbs as MuiBreadcrumbs, Typography } from "@material-ui/core";
import MuiLink from '@material-ui/core/Link';
import { Link } from 'react-router-dom'

type Props = {
  children: React.ReactNode
}

type CrumbProps = {
  href: string
  children: string
}

const Crumb = (props: Omit<CrumbProps, "href">) => {
  return <Typography color="textPrimary">{props.children}</Typography>
}

const CrumbLink = (props: CrumbProps) => {
  return <MuiLink color="inherit" component={React.forwardRef(({ className, onFocus, onBlur, children }: any, ref: any) => {
    return <Link ref={ref} className={className} onFocus={onFocus} onBlur={onBlur} to={props.href}>{children}</Link>
  })}>{props.children}</MuiLink>
}

const Breadcrumbs = (props: Props) => {
  return (
    <MuiBreadcrumbs aria-label="breadcrumb">
      {props.children}
    </MuiBreadcrumbs>
  )
}

export default Breadcrumbs

export { Breadcrumbs, CrumbLink, Crumb }