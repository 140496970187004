import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { AppBar, Toolbar, Menu, Button, IconButton, MenuItem, ListItemIcon, ListItemText, Divider, Typography } from '@material-ui/core';
import { Edit, Star, ExitToApp, Menu as MenuIcon } from '@material-ui/icons'
import { getUserManager } from "auth/user-manager"
import { User } from "oidc-client"
import { AuthContext } from "auth/auth"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
);

const selfHost =
  window.location.protocol +
  "//" +
  window.location.hostname +
  (window.location.port ? ":" + window.location.port : "");

function MobileNav() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { settings, user } = React.useContext(AuthContext)
  const classes = useStyles();
  const history = useHistory()

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const navigate = (path: string) => {
    handleClose()
    history.push(path)
  }

  // const logout = async () => {
  //   const mgr = await getUserManager(settings.client_id)
  //   const user = await mgr.getUser() as User
  //   await mgr.removeUser()
  //   handleClose()
  //   window.location.href = `https://xsided.io/oauth2/sessions/logout?post_logout_redirect_uri=${selfHost}&id_token_hint=${user.id_token}`
  // }

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="sticky">
      <Toolbar>
        <IconButton onClick={handleClick} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => navigate("/service/events")}>
            <ListItemIcon>
              <Star fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Events" />
          </MenuItem>
          <MenuItem onClick={() => navigate("/service/prizes")}>
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Prizes" />
          </MenuItem>
          <MenuItem onClick={() => navigate("/service/sponsors")}>
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Sponsors" />
          </MenuItem>
          <MenuItem onClick={() => navigate("/service/fanshouts")}>
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Fan shouts" />
          </MenuItem>
          {["admin"].includes(user.role)&&<MenuItem onClick={() => navigate("/service/categories")}>
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Categories" />
          </MenuItem>}
          {/* <Divider />
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <ExitToApp fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </MenuItem> */}
        </Menu>
        <Typography variant="h6" className={classes.title}>
          Service
        </Typography>
        <Button color="inherit" onClick={() => history.push("/launch")}>Launch Pad</Button>
      </Toolbar>
    </AppBar>
  );
}

export default MobileNav

{/* <AppBar
    title="Test"
    onLeftIconButtonTouchTap={this.handleToggle}
  />
  <LeftNav
      open={this.state.menuOpen}
      onRequestChange={open => this.setState({menuOpen: open})}
      docked={false}>

    <MenuItem onTouchTap={this.closeLeftNav} value={'/'} primaryText="Home"/>
</LeftNav> */}