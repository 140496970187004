import React, { useEffect, useState } from 'react'
import api from 'api/index'
import { UserData } from 'api/users'
import logger from 'xsided/xsided-logger-js'

export function useUser(user_id: string): ReadHook<UserData, API.Error> {
  type State = { user: UserData, loading: boolean, error?: API.Error }
  const [state, setState] = useState<State>({ user: {
    "birthday": "",
    "gender": "",
    "name": "",
    "picture": "",
    "user_id": ""
  }, loading: false })

  useEffect(() => {
    async function fetch() {
      try{
        setState({ ...state, loading: true })
        const user = await api.getUser(user_id)
        setState({ ...state, loading: false, user })
      } catch(e) {
        logger.error(e, 'Failed to get user', { user_id })
        setState({ ...state, loading: false, error: e })
      }
    }
    fetch()
  }, [user_id])

  return [state.user, state.error, state.loading]
}
