import React from 'react';
import { Button } from 'buttons'
import { roundFrames } from 'react-http/games';
import { Agenda, QuizData, Frame, Game } from 'api/games';
import { Grid, Typography, Box, TextField, makeStyles, RadioGroup, FormControlLabel, Radio, Divider, Snackbar } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Field, FieldArray, FieldInputProps, FormikProps } from 'formik'
import { XsidedSnackbar, XsidedSnackProps } from 'snackbar'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import DraggableItem from 'drag-and-drop/item'
import _ from 'lodash';
import { PrizeAllocation } from 'api/results';
import { TextFieldWithCounter } from 'forms/text-field-with-counter';

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: "#f3f4f9",
    width: '100%',
    position: 'relative',
  },
  index: {
    backgroundColor: "#0f1531",
    height: '100%',
    width: 40,
  },
  number: {
    color: "#FFF",
    fontWeight: theme.typography.fontWeightBold,
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  title: {
    fontSize: 24,
  },
  divider: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: 'transparent',
  },
  delete: {
    marginTop: 12,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.text.secondary,
  },
  bottom: {
    marginBottom: theme.spacing(4),
  }
}))

type CardProps = {
  value: Frame<QuizData>
  number: number
  name: string
  setCorrect: (answer_id: string) => void
  deleteQuestion: () => void
  disabled: boolean
  errors: any
  touched: any
}

const EditCard = (props: CardProps) => {
  const classes = useStyles()
  const questionTouched = _.get(props.touched, `data.question[0].value`, false)
  const questionError = _.get(props.errors, `data.question[0].value`, "")

  return <Card className={classes.card}>
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <Box className={classes.index}>
          <Typography color="textSecondary" variant="h4" component="h2" align="center" className={classes.number}>{props.number}</Typography>
        </Box>
      </Grid>
      <Grid item xs={true}>
        <CardContent>
          <Field
            name={`${props.name}.data.question.0.value`}
            as={TextFieldWithCounter}
            maxLength={90}
            fullWidth
            disabled={props.disabled}
            placeholder="Enter question"
            helperText={questionTouched && questionError ? questionError : ""}
            error={questionTouched && !!questionError}
            InputProps={{
              classes: {
                input: classes.title
              }
            }}
          />
          <Divider light className={classes.divider} />
          <RadioGroup aria-label="correct answer" name="letter" onChange={e => props.setCorrect(e.target.value)}>
            <Grid container>
              <Grid item xs={12} sm={9}>
                <Grid container item xs={12}>
                  <Grid item xs={true}></Grid>
                  <Grid item xs="auto"><Box component="p" style={{ color: "red" }}>{_.get(props.errors, `data.answers.missingCorrect`, "")}</Box></Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={9}>
                <FieldArray name="answers">
                  {(arrayHelpers) => (
                    props.value.data.answers.map((a, i) => {
                      const answerTouched = _.get(props.touched, `data.answers[${i}].answer[0].value`, false)
                      const errorText = _.get(props.errors, `data.answers[${i}].answer[0].value`, "")
                      return <Grid container item xs={12} key={a.answer_id}>
                        <Grid item xs={true}>
                          <Field
                            name={`${props.name}.data.answers.${i}.answer.0.value`}
                            as={TextFieldWithCounter}
                            maxLength={30}
                            fullWidth
                            disabled={props.disabled}
                            placeholder="Enter answer"
                            helperText={answerTouched && errorText ? errorText : ""}
                            error={answerTouched && !!errorText}
                          />
                        </Grid>
                        <Grid item xs="auto">
                          <Box ml={2}>
                            <FormControlLabel
                              disabled={props.disabled}
                              value={a.answer_id}
                              control={<Radio checked={props.value.data.answers[i].correct} />}
                              label={a.answer[0].letter}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    })
                  )}
                </FieldArray>
              </Grid>
              <Grid container item xs={true} justify="flex-end" alignItems="flex-end">
                <Typography className={classes.delete} onClick={() => false ? null : props.deleteQuestion()} color={false ? "textSecondary" : "textPrimary"}>Delete question</Typography>
              </Grid>
            </Grid>
          </RadioGroup>
        </CardContent>
      </Grid>
    </Grid>
  </Card>
}

type EditAgendaProps = {
  agenda: Agenda
  field: FieldInputProps<Agenda>
  form: FormikProps<{ game: Game, agenda: Agenda, prizeAllocations: PrizeAllocation[] }>
  disabled: boolean
}

export default function EditAgenda(props: EditAgendaProps) {
  const classes = useStyles()
  const [snack, setSnack] = React.useState<XsidedSnackProps>({ show: false, variant: "success", message: "Game saved", autoHideDuration: 3000 });
  const { errors, touched } = props.form

  return <DndProvider backend={Backend}>
    <div>
      <FieldArray name="agenda.frames">
        {(arrayHelpers) => (
          props.field.value.frames.filter(f => f.type === "quiz").map((frame, number) => {
            const index = props.field.value.frames.findIndex(f => f.frame_id === frame.frame_id)
            return <DraggableItem
              key={frame.frame_id}
              style={number === 0 ? {} : { marginTop: '32px' }}
              index={index}
              id={frame.frame_id}
              moveCard={(dragIndex, hoverIndex) => {
                if (dragIndex > hoverIndex) {
                  arrayHelpers.move(dragIndex - 1, hoverIndex - 1)
                  arrayHelpers.move(dragIndex, hoverIndex)
                  arrayHelpers.move(dragIndex + 1, hoverIndex + 1)
                } else {
                  arrayHelpers.move(hoverIndex - 1, dragIndex - 1)
                  arrayHelpers.move(hoverIndex, dragIndex)
                  arrayHelpers.move(hoverIndex + 1, dragIndex + 1)
                }
              }}
            >
              <Field
                name={`agenda.frames.${index}`}
                as={EditCard}
                number={number + 1}
                disabled={props.disabled}
                errors={errors.agenda && errors.agenda.frames && errors.agenda.frames[index] ? errors.agenda.frames[index] : {}}
                touched={touched.agenda && touched.agenda.frames && touched.agenda.frames[index] ? touched.agenda.frames[index] : {}}
                deleteQuestion={() => {
                  props.form.setFieldValue("agenda", {
                    ...props.field.value,
                    frames: props.field.value.frames
                      .slice(0, index - 1)
                      .concat(props.field.value.frames.slice(index + 2, props.field.value.frames.length))
                  })
                }}
                setCorrect={answer_id => {
                  props.form.setFieldValue("agenda", {
                    ...props.field.value,
                    frames: props.field.value.frames
                    .slice(0, index)
                    .concat({
                      ...props.field.value.frames[index],
                      data: {
                        ...props.field.value.frames[index].data,
                        answers: props.field.value.frames[index].data.answers.map(a => {
                          if (a.answer_id !== answer_id) {
                            return { ...a, correct: false }
                          }
                          return { ...a, correct: true }
                        })
                      }
                    })
                    .concat(props.field.value.frames.slice(index + 1, props.field.value.frames.length))
                  })
                }}
              />
            </DraggableItem>
          })
        )}
      </FieldArray>
      <Divider className={classes.divider} />
      <Grid container justify="space-between" className={classes.bottom}>
        <Button
          color="default"
          variant="contained"
          disabled={props.disabled}
          onClick={() => {
            const frames = roundFrames()
            const winnerFrameIndex = props.field.value.frames.findIndex(f => f.type === "winner")

            props.form.setFieldValue("agenda", {
              ...props.field.value,
              frames: [
                ...props.field.value.frames.slice(0, winnerFrameIndex)
                .concat(frames)
                .concat(...props.field.value.frames.slice(winnerFrameIndex, props.field.value.frames.length))
              ]
            })
          }}
        >
          Add question
        </Button>
      </Grid>

      <XsidedSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        show={snack.show}
        autoHideDuration={snack.autoHideDuration}
        onClose={() => setSnack(snack => ({ ...snack, show: false }))}
        variant={snack.variant}
        message={snack.message}
      />
    </div>
  </DndProvider>
}