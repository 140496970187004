import React from 'react'
import './trapezoid.css'

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  children: React.ReactNode
  backgroundColor: string
}

export function Trapezoid(props: Props) {
  const { backgroundColor, children, ...rest } = props
  return <div className="trapezoid-container" {...rest} >
    <div style={{ backgroundColor }}>
      {children}
    </div>
    <span className="triangle" style={{ backgroundColor }}></span>
  </div>
}