import React from 'react'
import { FaTimes } from 'react-icons/fa'
import { Button } from 'buttons'
import { useFanshoutCollection } from 'react-http/fanshouts'
import { RouteComponentProps, Prompt } from 'react-router'
import { FanshoutCollection } from 'api/fanshouts'
import { Breadcrumbs, CrumbLink, Crumb } from '../breadcrumbs'
import uuid from 'uuid/v4'
import { Typography, Box, CircularProgress, Grid, TextField, makeStyles, Divider } from '@material-ui/core'
import { Formik, Field, FieldArray } from 'formik'
import { XsidedSnackbar, XsidedSnackProps } from 'snackbar'
import logger from 'xsided/xsided-logger-js'
import { message } from 'forms/prompt-text'

type Props = {
  collection: FanshoutCollection
  saveCollection: (collection: FanshoutCollection) => Promise<FanshoutCollection>
}

const useStyles = makeStyles(theme => ({
  item: {
    '&:not(:first-child)': {
      marginTop: theme.spacing(2),
    }
  },
  add: {
    cursor: 'pointer',
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      color: theme.palette.text.secondary,
    }
  },
  divider: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: 'transparent',
  },
}))

const Details = (props: Props) => {
  const [snack, setSnack] = React.useState<XsidedSnackProps>({ show: false, variant: "success", message: "Fanshout collection saved", autoHideDuration: 3000 });
  const classes = useStyles()

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <CrumbLink href="/service/fanshouts">Fanshouts</CrumbLink>
        <Crumb>Fanshout</Crumb>
      </Breadcrumbs>
      <Typography variant="h4" component="h1">
        <Box fontWeight="fontWeightMedium">FANSHOUT</Box>
      </Typography>
      <hr />
      <Formik
        initialValues={props.collection}
        onSubmit={async (data, { resetForm }) => {
          try {
            setSnack({ show: true, variant: "info", message: "Saving...", autoHideDuration: null })
            const collection = await props.saveCollection(data)
            setSnack({ show: true, variant: "success", message: "Fanshout collection saved", autoHideDuration: 3000 })
            resetForm({ values: collection })
          } catch(err) {
            logger.error(err, "Failed to save fanshout collection", { data })
            setSnack({ show: true, variant: "error", message: "Failed to save fanshout collection", autoHideDuration: null })
          }
        }}
      >
        {({ values, handleSubmit, isSubmitting, setValues, dirty }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Prompt
              when={dirty}
              message={message}
            />
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name="name"
                  label="List name"
                  type="text"
                  variant="outlined"
                  disabled={isSubmitting}
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography component="h2" variant="h5">Fanshouts</Typography>
                <FieldArray name="collection">
                  {(arrayHelpers) => (
                    values.fanshouts.map((fanshout, index) => (
                      <Grid container key={fanshout.fanshout_id} className={classes.item} spacing={2}>
                        <Grid item xs={true}>
                          <Field
                            name={`fanshouts.${index}.value`}
                            as={TextField}
                            fullWidth
                            disabled={isSubmitting}
                            placeholder="Enter fanshout"
                          />
                        </Grid>
                        <Grid item>
                          <Box className="icon-background" style={{ background: '#292C38' }} onClick={() => setValues({
                            ...values,
                            fanshouts: [
                              ...values.fanshouts.slice(0, values.fanshouts.findIndex(f => f.fanshout_id === fanshout.fanshout_id))
                              .concat(values.fanshouts.slice(values.fanshouts.findIndex(f => f.fanshout_id === fanshout.fanshout_id) + 1, values.fanshouts.length))
                            ]
                          })}><FaTimes color="#FFF" /></Box>
                        </Grid>
                      </Grid>
                    ))
                  )}
                </FieldArray>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid container item justify="space-between">
              <Typography className={classes.add} onClick={() => setValues({
                ...values,
                fanshouts: values.fanshouts.concat({
                  fanshout_id: uuid(),
                  value: ""
                })
              })}>+ Add more</Typography>
              <Button
                type="submit"
                style={{ float: 'right' }}
                disabled={isSubmitting || !dirty}
                color="default"
                variant="contained"
              >
                Save
              </Button>
            </Grid>
          </form>
        )}
      </Formik>
      <XsidedSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        show={snack.show}
        autoHideDuration={snack.autoHideDuration}
        onClose={() => setSnack(snack => ({ ...snack, show: false }))}
        variant={snack.variant}
        message={snack.message}
      />
    </>
  )
}

export function FanshoutDetails(props: RouteComponentProps<{ listId: string }>) {
  const [fanshoutCollection, save, error, loading] = useFanshoutCollection(props.match.params.listId)

  if (loading) {
    return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </div>
  }

  return <>
    <Details
      collection={fanshoutCollection}
      saveCollection={save}
    />
  </>
}