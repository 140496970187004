import React from "react";
import { Route, Switch } from "react-router-dom";
import LaunchList from "launch/list";
import LaunchPad from "launch/pad";
import './launch.css'

// launch/
// launch/pad/id/ready
// launch/pad/id/done

export default function Launch() {
  document.title = "Xsided - Launch"
  return <div className="launch-container">
    <Switch>
      <Route exact path="/launch" component={LaunchList} />
      <Route exact path={["/launch/pad/:event_id/ready", "/launch/pad/:event_id/done"]} component={LaunchPad} />
    </Switch>
  </div>
}


