import * as React from 'react'
import { FlashOn, Settings } from '@material-ui/icons'
import { RouteComponentProps } from 'react-router-dom'
import './navigation-header.css'

const NavigationHeader = (props: RouteComponentProps) => {
  const isServiceRoute = props.location.pathname.startsWith("/service")
  const isLaunchRoute = props.location.pathname.startsWith("/launch")

  return (
    <>
      <div id="navigation-header-container">
        <div className="navigation-header-wrapper">

          <div className="navigation-header-button-wrapper" onClick={() => props.history.push("/service")}>
            <div className="navigation-header-button navigation-header-button-left" style={isServiceRoute ? { backgroundColor: "#202de5" } : {}}>
              <p className="navigation-header-button-text" style={isServiceRoute ? { left: 6, color: "#FFF" } : { left: 6 }}>ADMIN</p>
            </div>
            <div className="navigation-header-button-icon" style={isServiceRoute ? { left: 3, backgroundColor: "#FFF" } : { left: 3 }}>
              <Settings fontSize="small" />
            </div>
            <div className="navigation-header-button-left-triangle" style={isServiceRoute ? { borderTopColor: "#202de5" } : {}}></div>
          </div>

          <div className="navigation-header-button-wrapper" style={{ left: -22 }} onClick={() => props.history.push("/launch")}>
            <div className="navigation-header-button-right-triangle" style={isLaunchRoute ? { borderBottomColor: "#202de5" } : {}}></div>
            <div className="navigation-header-button navigation-header-button-right" style={isLaunchRoute ? { backgroundColor: "#202de5" } : {}}>
              <p className="navigation-header-button-text" style={isLaunchRoute ? { left: -6, color: "#FFF" } : { left: -6 }}>LIVE</p>
            </div>
            <div className="navigation-header-button-icon" style={isLaunchRoute ? { right: 3, backgroundColor: "#FFF" } : { right: 3 }}>
              <FlashOn fontSize="small" />
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default NavigationHeader