import React from 'react'
import { useAgenda } from 'react-http/games'
import uuid from 'uuid/v4'
import { Event } from 'api/events'
import { IoIosPlay } from 'react-icons/io'
import './ready.scss'
import { FaSpinner, FaExclamation, FaCheck } from 'react-icons/fa'
import { NumberSection } from './components/number-section'
import { Game } from 'api/games'
import { AuthContext } from 'auth/auth'

type LaunchMode = "live" | "test"
type Entry = {
  game: Game
  number: number
  start: (game_id: string, mode: LaunchMode) => void
}

type GameState = 'NOT_STARTED' | 'STARTING' | 'STARTED' | 'ERROR'
type LaunchButtonProps = {
  state: GameState
  onClick: () => void
}

const LaunchButton: React.FC<LaunchButtonProps> = ({ state, onClick }) => {
  switch (state) {
    case 'NOT_STARTED':
      return <button className="launch-show" onClick={onClick}><IoIosPlay /></button>
    case 'STARTING':
      return <button className="launch-show" onClick={() => {}}><FaSpinner className="icon-spin" /></button>
    case 'STARTED':
      return <button className="launch-show" onClick={() => {}}><FaCheck /></button>
    case 'ERROR':
      return <button className="launch-show" style={{ background: '#fa3232' }} onClick={() => {}}><FaExclamation /></button>
  }
}

const Entry = (props: Entry) => {
  const [state, setState] = React.useState<GameState>("NOT_STARTED")
  const [disabled, setDisabled] = React.useState(false)
  const agenda = useAgenda(props.game.agenda_id)

  if (agenda.loading || !agenda.agenda) {
    return <p>Loading...</p>
  }

  const fanshoutFrame = agenda.agenda.frames.find(f => f.type === "fanshout")

  const changeCountdown = async (value: number) => {
    if (disabled) return
    if (!fanshoutFrame) return

    setDisabled(true)
    await agenda.updateFrameDuration(props.game.game_id, props.game.agenda_id, fanshoutFrame.frame_id, value)
    setDisabled(false)
  }

  const countdown = fanshoutFrame ? Math.floor(fanshoutFrame.ticks) : 0
  const duration = agenda.agenda.frames.reduce((acc, curr) => acc + curr.ticks, 0)
  const minutes = Math.floor(duration / 60)
  const seconds = duration % 60 < 10 ? "0" + duration % 60 : duration % 60

  const startGame = () => {
    props.start(props.game.game_id, "live")
    setState("STARTED")
  }

  const testGame = () => props.start(props.game.game_id, "test")

  return (
    <>
      <div className="launch-pad-entry">
        <NumberSection>{props.number}</NumberSection>
        <div className="launch-pad-entry--info">
          <h3 className="launch-pad-entry--title">Quiz</h3>
          {props.game.sponsor_name ? <>
            <p className="launch-pad-entry--label">SPONSOR</p>
            <p className="launch-pad-entry--sponsor">{props.game.sponsor_name}</p>
          </>
          :
          null
          }
        </div>
        <div className="launch-pad-entry--countdown">
          <p style={{ marginTop: 0 }} className="launch-pad-entry--label">Countdown duration</p>
          <div className="launch-pad-entry--countdown-options">
            {[1,2,3,4,5].map(n => {
              const activeClass = countdown / 60 === n ? "active" : ""
              const disabledClass = disabled ? "disabled" : ""

              return <div onClick={() => changeCountdown(n * 60)} key={n} className={`${activeClass} ${disabledClass}`}>
                <p>{n}</p>
              </div>
            }
            )}
          </div>
        </div>
        <div className="launch-pad-entry--duration">
          <p style={{ margin: 0 }} className="launch-pad-entry--label">SHOW DURATION</p>
          <h1 style={{ margin: 0 }}>{`${minutes}:${seconds}`}</h1>
        </div>
        <div className="launch-pad-entry--action">
          <button className="launch-test" onClick={testGame}>TEST</button>
          <LaunchButton state={state} onClick={startGame} />
        </div>
      </div>
    </>
  )
}

type Props = {
  event: Event
  games: (Game & { index: number })[]
}

export function LaunchPadReady(props: Props) {
  const { settings } = React.useContext(AuthContext)

  React.useEffect(() => {
    if (!settings.show_url) {
      console.error('Missing configuration. show_url is not configured for client ', settings.client_id)
    }
  }, [settings.show_url])

  if (!props.games.length) {
    return <p>No games to launch...</p>
  }

  const start = (game_id: string, mode: LaunchMode) => {
    const instance_id = uuid()

    window.open(`http://${settings.show_url}/event/${props.event.event_id}/game/${game_id}/instance/${instance_id}/launch/${mode}`, '_blank')
  }

  return <>
    {props.games.map(game => <Entry key={game.game_id} number={game.index + 1} game={game} start={start} />)}
  </>
}