interface Message {
  message: String
  level: string
  app_version: string
  extra?: any
  oidc_client?: string
  user_id?: string
  trace_id?: string
  owner_id?: string
  timestamp: number
  user_agent: string
  url: string
}

interface ErrorMessage extends Message {
  error: any
}

class Logger {
  user_id?: string
  app_version: string = "app_version not supplied"
  oidc_client?: string
  owner_id?: string
  user_agent: string = window && window.navigator && window.navigator.userAgent ? window.navigator.userAgent : "no user agent"
  output = (msg: ErrorMessage | Message) => { console.log(msg) }

  private getUrl() {
    if (window && window.location && window.location.href) {
      return window.location.href
    }
    return ""
  }

  error = (err: any, msg: string, extra?: any): any => {
    if (err && err.config && err.config.headers && err.config.headers.Authorization) {
      err.config.headers.Authorization = undefined
    }
    if (err && err.message) {
      err.msg = err.message
    }
    return this.output({
      user_id: this.user_id,
      app_version: this.app_version,
      oidc_client: this.oidc_client,
      level: "error",
      message: msg,
      error: err,
      owner_id: this.owner_id,
      extra: extra,
      timestamp: Date.now(),
      user_agent: this.user_agent,
      url: this.getUrl()
    })
  }

  info = (msg: string, extra?: any): any => {
    return this.output({
      user_id: this.user_id,
      app_version: this.app_version,
      oidc_client: this.oidc_client,
      level: "info",
      message: msg,
      owner_id: this.owner_id,
      extra: extra,
      timestamp: Date.now(),
      user_agent: this.user_agent,
      url: this.getUrl()
    })
  }
}

const logger = new Logger()

export default logger

