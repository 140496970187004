import React, { useCallback } from 'react'
import { FaTimes } from 'react-icons/fa'
import { useDropzone } from 'react-dropzone'
import { CircularProgress } from "@material-ui/core";
import { Typography } from '@material-ui/core'

type Props = {
  label: string
  src?: string
  dimensions?: {
    width: number
    height: number
  }
  disabled?: boolean
  changeImage: (file: File) => Promise<any>
  removeImage: () => void
}

export function ImageDropZoneWithLabel(props: Props) {
  const [loading, setLoading] = React.useState(false)
  const onDrop = useCallback(async acceptedFiles => {
    if (!acceptedFiles || props.disabled) return
    const file = acceptedFiles[0]

    if (["image/png", "image/jpeg"].includes(file.type)) {
      setLoading(true)
      await props.changeImage(acceptedFiles[0])
      setLoading(false)
    } else {
      alert('Invalid file type provided. File must be png or jpeg.')
    }

  }, [props])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop})

  return <div className={`form-text form-image`}>
    <div>
      <Typography color="textSecondary" style={{ fontSize: 12 }}>{props.label}</Typography>
      {props.dimensions ? <Typography  color="textSecondary" style={{ fontSize: 12 }}>{props.dimensions.width} x {props.dimensions.height} px</Typography> : null}
    </div>
    <div className="form-image-editor">
      <div {...getRootProps()} onClick={props.disabled ? () => {} : getRootProps().onClick } style={props.disabled ? { background: "#f2f2f2", cursor: 'no-drop' } : {}} className="form-image-placeholder" >
        <input {...getInputProps()} />
        {
          loading ? <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div> :
          props.src ? <img src={props.src} />
          :
          isDragActive ?
            <p>Drop the files here ...</p> :
            <p>Drag 'n' drop some files here, or click to select files</p>
        }
      </div>
      <div className="form-image-editor-controls">
        <button type="button" onClick={props.removeImage}>
          <FaTimes style={{ color: '#838383' }} />
        </button>
      </div>
    </div>
  </div>
}