import * as React from "react"
import { RouteComponentProps } from "react-router-dom"
import { getUserManager } from './user-manager'
import { AuthContext } from "./auth"
import Logger from 'xsided/xsided-logger-js'

const LoginCallback = (props: RouteComponentProps) => {
  const { settings } = React.useContext(AuthContext)
  const mgr = getUserManager(settings.client_id)

  React.useEffect(() => {
    mgr.signinRedirectCallback()
    .then((user) => {
      let redirectUri = "/launch"
      if (user.state) {
        redirectUri = user.state
      }
      props.history.replace(redirectUri)
    })
    .catch(function(err) {
      Logger.error(err, "Login callback error")
      mgr.signinRedirect()
    })
  }, [])

  return <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <h4>Loading...</h4>
  </div>
}

export default LoginCallback;