import * as React from "react"
import { getUserManager } from './user-manager'
import { AuthContext } from "./auth"
import Logger from 'xsided/xsided-logger-js'

const LogoutCallback = () => {
  const { settings } = React.useContext(AuthContext)

  React.useEffect(() => {
    if (settings.client_id) {
      const mgr = getUserManager(settings.client_id)
      mgr.signoutRedirectCallback()
      .catch(err => Logger.error(err, 'Signout redirect callback failed'))
      .finally(() => mgr.signinRedirect())
    }
  }, [settings.client_id])

  return <div>
    <p>Loading...</p>
  </div>
}

export default LogoutCallback
