import React from "react";
import { Route, Redirect } from "react-router-dom";

import Events from "./events";
import Prizes from "./prizes";
import Menu from "../menu";
import MobileNav from "../menu/mobile-nav";
import Sponsors from './sponsors'
import Fanshouts from "./fanshouts";
import Categories from "./categories";
import { Box, Hidden } from "@material-ui/core";
import { AuthContext } from "auth/auth";

export default function Service() {
  const { user } = React.useContext(AuthContext)

  document.title = "Xsided - Service"
  return <div className="container">
    <Hidden smDown>
      <Menu />
    </Hidden>
    <Box width={1} height={1}>
      <Hidden mdUp>
        <MobileNav />
      </Hidden>
      <Box height={1} width={1} padding="20px 35px">
        <Route exact path="/service" component={() => <Redirect to="/service/events" />} />
        <Route path="/service/events" component={Events} />
        <Route path="/service/prizes" component={Prizes} />
        <Route path="/service/sponsors" component={Sponsors} />
        <Route path="/service/fanshouts" component={Fanshouts} />
        {["admin"].includes(user.role) ? <Route path="/service/categories" component={Categories} /> : null}
      </Box>
    </Box>
  </div>
}