import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Page404 from './page-404';

import Service from "./service";
import Launch from "./launch";
import AuthProvider from "auth/auth";
import LogoutCallback from "auth/logout.callback";
import LoginCallback from "auth/login.callback";
import NavigationHeader from "menu/navigation-header";
import SessionExpired from "./session-expired";
import Unauthorized from "./unauthorize";

export default function Router() {
  return <BrowserRouter>
    <Switch>
      <Route exact path="/session-expired" component={SessionExpired} />
      <Route exact path="/unauthorized" component={Unauthorized} />
      <Route path="/logout/callback" exact={true} component={LogoutCallback} />
      <Route path="/login/callback" exact={true} component={LoginCallback} />
      <AuthProvider>
        {(user) => {
          if (["admin", "publisher"].includes(user.role)) {
            return <>
              <div style={{ position: 'fixed', top: 0, right: 0, left: 0, zIndex: 1000 }}>
                <Route component={NavigationHeader} />
              </div>
              <div style={{ position: 'absolute', left: 0, top: 44, width: '100%', height: '100%' }}>
                <Switch>
                  <Route path="/" exact component={() => <Redirect to="/launch" />} />
                  <Route path="/service" component={Service} />
                  <Route path="/launch" component={Launch} />
                  <Route component={Page404} />
                </Switch>
              </div>
            </>
          }

          return <>
            <Switch>
              <Route path="/" exact component={() => <Redirect to="/launch" />} />
              <Route path="/launch" exact component={Launch} />
              <Route component={Page404} />
            </Switch>
          </>
        }}
      </AuthProvider>
    </Switch>
  </BrowserRouter>
}
