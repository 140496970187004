import * as React from 'react'

type Props = {

}

const Unauthorized = (props: Props) => {
  return (
    <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <h3>You are not allowed to view this page. </h3>
      <p>Please contact to the Administrator.</p>
    </div>
  )
}

export default Unauthorized