import * as React from 'react'
import { storiesOf } from '@storybook/react'
import { IoIosFlash } from 'react-icons/io'
import { FaChevronRight, FaStar, FaSpinner } from 'react-icons/fa'
import './list.css'
import { RouteComponentProps } from 'react-router-dom'
import { useEventList } from 'react-http'
import moment from 'moment'
import { Trapezoid } from 'trapezoid/trapezoid'

type ListProps = {
  entries: ListEntry[]
  list_title: string
  onClick: (event_id: string) => void
}

type ListEntry = {
  event_id: string
  date?: string
  title?: string
  category?: string
  backgroundColor: string
}

const ListEntry = (props: ListEntry & { onClick: () => void }) => {
  return (
    <>
      <div className="launch-list-entry-date"><p>{props.date}</p></div>
      <div className="launch-list-entry-info" style={{ backgroundColor: props.backgroundColor }}>
        <p className="launch-list-entry-title">{props.title}</p>
        <p className="launch-list-entry-category">{props.category}</p>
      </div>
      <div className="launch-list-entry-action" style={{ backgroundColor: props.backgroundColor }}>
        <button onClick={props.onClick}>
          <div className="launch-list-entry-action-icon">
            <IoIosFlash />
          </div>
          <p>Launch pad</p>
          <FaChevronRight className="launch-list-entry-action-nav-right" />
        </button>
      </div>
    </>
  )
}

function List(props: ListProps) {
  return <div className="launch-list-container">
    <Trapezoid backgroundColor="#f2f2f2">
      <div className="launch-list-event-tab">
        <span><FaStar /></span>
        <h1>{props.list_title}</h1>
      </div>
    </Trapezoid>
    <div className="launch-list">
      <p className="launch-list-label">Date</p>
      <p className="launch-list-label">Event</p>
      <p className="launch-list-label">LAUNCH GAMES AT YOUR EVENTS</p>
      {props.entries.map(entry => <ListEntry key={entry.event_id} {...entry} onClick={() => props.onClick(entry.event_id)} />)}
    </div>
  </div>
}

// (storiesOf('Launch', module)).add(
//   'List',
//   (() => <List
//     entries={[{
//       event_id: "event-1",
//       date:"07-10-2020",
//       title:"Treepark Rangers - The Falcons",
//       category:"Soccer - Super league",
//       backgroundColor: "#FFF"
//     }]}
//     onClick={() => {}}
//   />)
// )

export default function LaunchList(props: RouteComponentProps) {
  const [events, error, loading] = useEventList()
  const goToLaunchPad = (event_id: string) => {
    props.history.push(`/launch/pad/${event_id}/ready`)
  }
  const previousEvents = events.filter(e => Date.now() > e.end_date * 1000).sort((a, b) => b.end_date - a.end_date)
  const upcomingEvents = events.filter(e => Date.now() < e.end_date * 1000)

  if (loading) {
    return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <FaSpinner style={{ color: '#FFF', fontSize: 80 }} className="icon-spin" />
    </div>
  }

  // Make error optional in hook
  // if (error) {
  //   console.log(error)
  //   return <div>An error occured</div>
  // }

  return <div>
    <List
      entries={upcomingEvents.map(e => ({ 
        event_id: e.event_id,
        date: moment.unix(Number(e.start_date)).format("DD-MM-YYYY"),
        backgroundColor: Date.now() > e.end_date * 1000 ? '#d6d6d6' : '#FFF',
        title: e.title,
        category: e.category,
      }))}
      list_title="Upcoming Events"
      onClick={goToLaunchPad} />
    <List
      entries={previousEvents.map(e => ({ 
        event_id: e.event_id,
        date: moment.unix(Number(e.start_date)).format("DD-MM-YYYY"),
        backgroundColor: Date.now() > e.end_date * 1000 ? '#d6d6d6' : '#FFF',
        title: e.title,
        category: e.category,
      }))}
      list_title="Previous Events"
      onClick={goToLaunchPad}
      />
  </div>
}
