import React, { useEffect, useState } from 'react'
import { Category } from 'api/categories'
import api from 'api'
import logger from 'xsided/xsided-logger-js'

export function useCategories(): ReadHook<Category[], API.Error> {
  type State = { categories: Category[], loading: boolean, error?: API.Error }
  const [state, setState] = useState<State>({ categories: [], loading: false })

  useEffect(() => {
    async function fetch() {
      try {
        setState({ ...state, loading: true })
        const res = await api.listCategories()
        setState({ ...state, categories: res, loading: false, error: null })
      } catch(e) {
        setState({ ...state, loading: false, error: e })
        if (e.response && e.response.status === 404) {
          // TODO fix
          return
        }
        logger.error(e, "Failed to fetch categories")
      }
    }
    fetch()
  }, [])

  return [state.categories, state.error, state.loading]
}

type SaveCategory = (category: Category) => Promise<Category>
export function useCategory(category_id: string): [Category, SaveCategory, API.Error, boolean] {
  type State = { category: Category, loading: boolean, error?: API.Error }
  const [state, setState] = useState<State>({ category: { category_id, name: "" }, loading: true })

  React.useEffect(() => {
    async function fetch() {
      try {
        setState({ ...state, loading: true })
        const res = await api.getCategory(category_id)
        setState({ ...state, category: res, loading: false })
      } catch(e) {
        setState({ ...state, loading: false, error: e })
        logger.error(e, "Failed to fetch category")
      }
    }
    fetch()
  }, [category_id])

  const saveCategory = (category: Category): Promise<Category> => api.upsertCategory(category)

  return [state.category, saveCategory, state.error, state.loading]
}