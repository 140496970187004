import * as Oidc from "oidc-client";
import { User } from './types'
import { setAuthToken } from '../http'

const selfHost =
  window.location.protocol +
  "//" +
  window.location.hostname +
  (window.location.port ? ":" + window.location.port : "");
var settings = {
  authority: "https://xsided.io",
  client_id: "",
  redirect_uri: selfHost + "/login/callback",
  silent_redirect_uri: selfHost + "/silent-login-callback.html",
  post_logout_redirect_uri: selfHost + "/logout/callback",
  response_type: "id_token token",
  scope:
    "openid users events events-admin games games-admin results results-admin fanshout fanshout-admin sponsors sponsors-admin organizers organizers-admin themes-admin categories categories-admin launch",
  loadUserInfo: false,
  automaticSilentRenew: true,
};

export const getUserManager = (client_id: string) => {
  const mgr = new Oidc.UserManager({ ...settings, client_id })

  mgr.events.addUserLoaded((user: User) => {
    console.log('User loaded', user)
    setAuthToken(user.access_token)
  })

  return mgr
}