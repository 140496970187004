import React from 'react'
import './details.scss'
import { ImageDropZoneWithLabel } from 'forms/image'
import { Button } from 'buttons'
import { usePrize } from 'react-http/results'
import { RouteComponentProps, Prompt } from 'react-router-dom'
import { Prize } from 'api/results'
import cdn from 'http/cdn'
import { Breadcrumbs, CrumbLink, Crumb } from '../breadcrumbs'
import { AuthContext } from 'auth/auth'
import { Typography, Box, CircularProgress, TextField, Grid } from '@material-ui/core'
import { Formik, Field } from 'formik'
import { XsidedSnackbar, XsidedSnackProps } from 'snackbar'
import logger from 'xsided/xsided-logger-js'
import { message } from 'forms/prompt-text'

type Props = {
  prize: Prize
  updateValue: (key: string, value: string) => void
  savePrize: (prize: Prize) => Promise<Prize>
  changeImage: (file: File) => Promise<string>
}

const Details = (props: Props) => {
  const [snack, setSnack] = React.useState<XsidedSnackProps>({ show: false, variant: "success", message: "Prize saved", autoHideDuration: 3000 });

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <CrumbLink href="/service/prizes">Prizes</CrumbLink>
        <Crumb>Prize</Crumb>
      </Breadcrumbs>
      <Typography variant="h4" component="h1">
        <Box fontWeight="fontWeightMedium">PRIZE</Box>
      </Typography>
      <hr />
      <Formik
        initialValues={props.prize}
        onSubmit={async (data, { resetForm }) => {
          try {
            setSnack({ show: true, variant: "info", message: "Saving...", autoHideDuration: null })
            const prize = await props.savePrize(data)
            resetForm({ values: prize})
            setSnack({ show: true, variant: "success", message: "Prize saved", autoHideDuration: 3000 })
          } catch(err) {
            logger.error(err, "Failed to save prize", { data })
            setSnack({ show: true, variant: "error", message: "Failed to save prize", autoHideDuration: null })
          }
        }}
      >
        {({ values, handleSubmit, isSubmitting, setFieldValue, dirty }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Prompt
              when={dirty}
              message={message}
            />
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Field
                  fullWidth
                  name="title"
                  label="Title"
                  type="text"
                  variant="outlined"
                  disabled={isSubmitting}
                  as={TextField}
                />
              </Grid>
              <Grid container></Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  fullWidth
                  name="description"
                  label="Description"
                  type="text"
                  variant="outlined"
                  multiline
                  disabled={isSubmitting}
                  as={TextField}
                />
              </Grid>
              <Grid container></Grid>
              <Grid item xs={12}>
                <ImageDropZoneWithLabel
                  disabled={isSubmitting}
                  removeImage={() => setFieldValue("image_url", "")}
                  label="Event image"
                  src={values.image_url}
                  changeImage={img => props.changeImage(img)
                    .then(path => setFieldValue("image_url", path))
                    .catch(err => setSnack({ show: true, variant: "error", message: "Failed to update prize image", autoHideDuration: null }))
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  style={{ float: 'right' }}
                  disabled={isSubmitting || !dirty}
                  color="default"
                  variant="contained"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <XsidedSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        show={snack.show}
        autoHideDuration={snack.autoHideDuration}
        onClose={() => setSnack(snack => ({ ...snack, show: false }))}
        variant={snack.variant}
        message={snack.message}
      />
    </>
  )
}

export function PrizeDetails(props: RouteComponentProps<{ prizeId: string }>) {
  const [prize, updateValue, savePrize, error, loading] = usePrize(props.match.params.prizeId)
  const { user } = React.useContext(AuthContext)

  const changeImage = (file: File) => {
    return cdn.uploadImage(file, "prize-image", user.owner_id).then(({ path }) => path)
  }

  if (loading) {
    return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </div>
  }

  if (error && !(error.response && error.response.status === 404)) {
    return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography component="h1" variant="body1">
        <Box whiteSpace="pre" textAlign="center">{`An error occured... \nWe couldn't retrieve your prize.`}</Box>
      </Typography>
    </div>
  }

  return <Details
    prize={prize}
    updateValue={updateValue}
    savePrize={savePrize}
    changeImage={changeImage}
  />
}
