import React from "react"
import { Link } from 'react-router-dom'

import "./menu.scss"
import { FaStar, FaPencilAlt } from "react-icons/fa"
import { AuthContext } from "auth/auth"
import { getUserManager } from "auth/user-manager"
import { User } from "oidc-client"

const selfHost =
  window.location.protocol +
  "//" +
  window.location.hostname +
  (window.location.port ? ":" + window.location.port : "");

export default function Menu() {
  const { settings, user } = React.useContext(AuthContext)

  // const logout = async () => {
  //   const mgr = await getUserManager(settings.client_id)
  //   const user = await mgr.getUser() as User
  //   await mgr.removeUser()
  //   window.location.href = `https://xsided.io/oauth2/sessions/logout?post_logout_redirect_uri=${selfHost}&id_token_hint=${user.id_token}`
  // }

  return <div style={{ height: '100%', width: 240 }}>
    <div style={{ width: 240, position: 'relative', left: 0 }}></div>
    <div className="menu" style={{ position: 'fixed' }}>
      <div className="menu-background"></div>
        <div className="menu-links-wrapper">
          <Link to="/service/events" className="menu-title"><FaStar />Event list</Link>
          <p className="menu-info">Create and edit content</p>
          <Link to="/service/prizes" className="menu-title"><FaPencilAlt />Prizes</Link>
          <Link to="/service/sponsors" className="menu-title"><FaPencilAlt />Sponsors</Link>
          <Link to="/service/fanshouts" className="menu-title"><FaPencilAlt />Fan shouts</Link>
          {["admin", "owner", "organizer"].includes(user.role) ? <Link to="/service/categories" className="menu-title"><FaPencilAlt />Categories</Link> : null}
        </div>
        <br />
        <br />
        <br />
        {/* <p className="menu-title" onClick={logout}>Logout</p> */}
    </div>
  </div>
}