import React from 'react'
import { NumberSection } from './components/number-section'
import './done.scss'
import { GameInstance, Game } from 'api/games'
import { PrizeAllocation } from 'api/results'
import moment from 'moment'
import { useLeaderboard } from 'react-http/games'
import { useUserPrizeForGameInstance, usePrizeAllocationList } from 'react-http/results'
import { useUser } from 'react-http/user'

type Entry = {
  number: number
  instances: GameInstance[]
  game: Game
}

const User: React.FC<{ userId: string }> = ({ userId }) => {
  const [user] = useUser(userId)

  return <div>{user.name}</div>
}

const UserPrizes: React.FC<{ allocation: PrizeAllocation, game_id: string, instance_id: string }> = ({ allocation, game_id, instance_id }) => {
  const [userPrizes] = useUserPrizeForGameInstance(allocation.allocation_id, game_id, instance_id)

  if (userPrizes.length === 0) return null

  return <div style={{ display: 'flex', flex: 1 }}>
    <div style={{ padding: 4, flex: 1 }}>
      <label style={{ marginTop: 0 }}>PRIZE</label>
      <p>{allocation.prize.title}</p>
    </div>
    <div style={{ padding: 4, flex: 1 }}>
      <label style={{ marginTop: 0 }}>Winners</label>
      {userPrizes.map(up => <User key={up.user_prize_id} userId={up.user_id} />)}
    </div>
  </div>
}

const Leaderboard: React.FC<{ game_id: string, instance_id: string }> = ({ game_id, instance_id }) => {
  const { leaderboard, loading, error } = useLeaderboard(game_id, instance_id)

  if (loading) {
    return <p>Loading...</p>
  }

  return <div>Participants: <b>{leaderboard.total}</b></div>
}

const GameInstances: React.FC<{ instances: GameInstance[], game_id: string, prizeAllocations: PrizeAllocation[] }> = ({ instances, game_id, prizeAllocations }) => {
  return (
    <div style={{ display: 'flex', flex: 1, background: '#FFF' }}>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', borderTop: '2px solid #f2f2f2' }}>
        {instances.sort((a, b) => b.timestamp - a.timestamp).map(instance => (
          <div key={instance.instance_id} style={{ display: 'flex' }}>
            <div id={"INSTANCE" + instance.instance_id} style={{ display: 'flex', flex: 1, borderTop: '2px solid #f2f2f2', padding: '8px 8px 8px 28px' }}>
              <div style={{ flex: 1 }}>{prizeAllocations.map(pa => <UserPrizes allocation={pa} key={instance.instance_id + pa.allocation_id} game_id={game_id} instance_id={instance.instance_id} />)}</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', borderLeft: '2px solid #f2f2f2', borderTop: '2px solid #f2f2f2', width: 200, padding: 8 }}>
              <Leaderboard instance_id={instance.instance_id} game_id={instance.game_id} />
              <p style={{ margin: 0 }}>DATE: <b>{moment.unix(instance.timestamp).format("DD.MM.YYYY - HH:mm")}</b></p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

function Entry(props: Entry) {
  const [prizeAllocations, , , prizeAllocationError, prizeAllocationLoading] = usePrizeAllocationList(props.game.game_id)

  return (
    <div className="launch-pad-done-entry">
      <NumberSection>{props.number}</NumberSection>
      <div style={{ flex: 1 }}>
        <div className="launch-pad-done-entry--game">
          <h3 style={{ marginTop: 0 }} className="launch-pad-entry--title">Quiz</h3>
          {props.game.sponsor_name ? <>
            <p className="launch-pad-entry--label">SPONSOR</p>
            <p className="launch-pad-entry--sponsor">{props.game.sponsor_name}</p>
          </>
          :
          null
          }
        </div>
        <GameInstances instances={props.instances} game_id={props.game.game_id} prizeAllocations={prizeAllocations} />
      </div>
    </div>
  )
}

type Props = {
  instances: GameInstance[][]
  games: Game[]
}

export function LaunchPadDone(props: Props) {
  return <>
    {props.instances.map((instances, index) => {
      if (instances.length === 0) return null
      const game_id = instances[0].game_id
      const game = props.games.find(g => g.game_id === game_id) as Game

      return (
        <Entry
          key={index}
          game={game}
          number={index + 1}
          instances={instances}
        />
      )
    })}
  </>
}
