import React from 'react';
import { TextField, TextFieldProps } from "@material-ui/core";

type Props = {
  maxLength: number
} & TextFieldProps

export const TextFieldWithCounter = ({ maxLength, ...props }: Props) => {
  const charactersLeft = props.value ? maxLength - (props.value as string).length : maxLength
  return <div style={{ position: 'relative' }}>
    <TextField {...props} />
    <div style={{ position: 'absolute', right: 6, top: 6, bottom: 0  }}>
      <p style={{ margin: 'auto' }}>{charactersLeft}</p>
    </div>
  </div>
}
