import * as React from 'react'
import { makeStyles, IconButton } from "@material-ui/core";
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import clsx from 'clsx';

import { amber, green } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const CustomSnackbar = (props: any) => {
  const classes = useStyles();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  )
}

export type XsidedSnackProps = {
  show: boolean
  variant: "success" | "info" | "error"
  message: string
  autoHideDuration: number | null
}

type Props = {
  anchorOrigin: SnackbarOrigin
  onClose: () => void
} & XsidedSnackProps

const XsidedSnackbar = (props: Props) => {
  return (
    <Snackbar
      anchorOrigin={props.anchorOrigin}
      open={props.show}
      autoHideDuration={props.autoHideDuration}
      onClose={props.onClose}
    >
      <CustomSnackbar
        onClose={props.onClose}
        variant={props.variant}
        message={props.message}
      />
    </Snackbar>
  )
}

export default CustomSnackbar
export { XsidedSnackbar }
