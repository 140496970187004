import React, { useState, useEffect } from 'react'
import { Sponsor } from 'api/sponsors'
import api from 'api'
import uuid from 'uuid/v4'
import logger from 'xsided/xsided-logger-js'

type UpdateSponsor = (sponsor: Sponsor) => void
type SaveSponsor = (sponsor: Sponsor) => Promise<Sponsor>
export function useSponsor(sponsor_id: string): ReadWriteHook<Sponsor, UpdateSponsor, SaveSponsor, API.Error> {
  type State = { sponsor: Sponsor, loading: boolean, error?: API.Error }
  const defaultSponsor = { sponsor_id: uuid(), name: "", images: { banner_url: "", logo_url: "" } }
  const [state, setState] = useState<State>({ sponsor: defaultSponsor, loading: false })

  useEffect(() => {
    async function fetch() {
      try {
        setState({ ...state, loading: true })
        const res = await api.getSponsor(sponsor_id)
        setState({ ...state, loading: false, sponsor: res })
      } catch(e) {
        logger.error(e, 'Failed to fetch sponsor', { sponsor_id })
        setState({ ...state, sponsor: defaultSponsor, error: e, loading: false })
      }

    }
    fetch()
  }, [sponsor_id])

  const updateSponsor = (sponsor: Sponsor) => {
    return setState({ ...state, sponsor })
  }
  const saveSponsor = (sponsor: Sponsor) => {
    return api.upsertSponsor(sponsor.sponsor_id, sponsor)
  }

  return [state.sponsor, updateSponsor, saveSponsor, state.error, state.loading]
}

export function useSponsorList(): ReadHook<Sponsor[], API.Error> {
  type State = { list: Sponsor[], loading: boolean, error?: API.Error }
  const [state, setState] = useState<State>({ list: [], loading: false })

  useEffect(() => {
    async function fetch() {
      try {
        setState({ ...state, loading: true, error: null })
        const list = await api.listSponsors()
        setState({ ...state, loading: false, list })
      } catch(e) {
        setState({ ...state, loading: false, error: e })
        logger.error(e, 'Failed to list sponsors')
        alert('Failed to list sponsors')
      }
    }
    fetch()
  }, [])

  return [state.list, state.error, state.loading]
}